// SSI 0094 - GCOLLE - Pagina de Eventos, Ícone usuário. Pagina de Notificações corerção socket. - 17/01/2025
import React, { useState, useEffect, useContext } from "react";
import { Spinner, Container, Row, Col, Modal, Form } from "react-bootstrap";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faComment,
  faHeart,
  faShare,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import PageBreadcrumb, {
  PageBreadcrumbItem,
} from "components/common/PageBreadcrumb";
import Button from "components/base/Button";
import UserContext from "components/UserContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Avatar from "components/base/Avatar";
import CustomFooter from "./Footer/CustomFooter";

export const timelineBreadcrumb: PageBreadcrumbItem[] = [
  { label: "Home", link: "/home" },
  { label: "Eventos", active: true },
];

interface Evento {
  id: string;
  titulo: string;
  descricao: string;
  imagem?: string;
  createdAt: string;
  likes: number;
  commentsCount?: number;
  sharesCount?: number;
}

interface Comentario {
  id: number;
  usuario_id?: number;
  comentario: string;
  createdAt: string;
  nome: string;
  avatar: string;
}

const ListarEventos = () => {
  const [eventos, setEventos] = useState<Evento[]>([]);
  const [carregando, setCarregando] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [totalPaginas, setTotalPaginas] = useState(1);
  const { user: userLocal } = useContext(UserContext);
  const [likedEvents, setLikedEvents] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Evento | null>(null);
  const [showCommentBox, setShowCommentBox] = useState<string | null>(null);
  const [commentText, setCommentText] = useState("");
  const [comentariosModal, setComentariosModal] = useState<Comentario[]>([]);
  const [expanded, setExpanded] = useState(false);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const pageParam = searchParams.get("page");
  const [pagina, setPagina] = useState<number>(
    pageParam ? parseInt(pageParam, 10) : 1,
  );

  const eventosPorPagina = 4;
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const novaPagina = pageParam ? parseInt(pageParam, 10) : 1;
    setPagina(novaPagina);
  }, [pageParam]);

  useEffect(() => {
    const localizarPaginaDoEvento = async () => {
      if (!id) {
        console.warn("Nenhum ID fornecido. Carregando a página padrão.");
        setInitialLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${API_URL}/eventos/buscarPagina?id=${id}&limit=${eventosPorPagina}`,
        );

        if (!response.ok) {
          throw new Error("Erro ao localizar página do evento");
        }

        const data = await response.json();

        if (data?.pagina) {
          if (data.pagina !== pagina) {
            setPagina(data.pagina);
            navigate(`/pages/atualizacoes/listar-eventos?page=${data.pagina}`, {
              replace: true,
            });
          }
        } else {
          // Redireciona para página 1 se o evento não for encontrado
          navigate(`/pages/atualizacoes/listar-eventos?page=1`, {
            replace: true,
          });
        }
      } catch (error) {
        console.error("Erro ao localizar página do evento:", error);
        // Redireciona para página 1 em caso de erro
        navigate(`/pages/atualizacoes/listar-eventos?page=1`, {
          replace: true,
        });
      } finally {
        setInitialLoading(false);
      }
    };

    localizarPaginaDoEvento();
  }, [id, API_URL, eventosPorPagina, navigate, pagina]);

  useEffect(() => {
    const fetchLikedEvents = async () => {
      if (!userLocal?.id_func) return;

      try {
        const response = await fetch(
          `${API_URL}/eventos/usuarios/${userLocal.id_func}/eventos-curtidos`,
        );

        if (response.ok) {
          const likedEventsData = await response.json();
          setLikedEvents(likedEventsData?.likedEventIds?.map(String) || []);
        } else {
          console.error("Erro ao carregar eventos curtidos");
        }
      } catch (error) {
        console.error("Erro na requisição de curtidas:", error);
      }
    };

    fetchLikedEvents();
  }, [API_URL, userLocal?.id_func]);

  useEffect(() => {
    if (initialLoading) return;

    const fetchData = async () => {
      setCarregando(true);
      try {
        const response = await fetch(
          `${API_URL}/eventos/buscar?page=${pagina}&limit=${eventosPorPagina}`,
        );

        if (!response.ok) {
          throw new Error("Erro ao carregar eventos.");
        }

        const eventosData = await response.json();

        const eventosComContagens = await Promise.all(
          eventosData.eventos.map(async (evento: { id: string }) => {
            const [likesRes, commentsRes] = await Promise.all([
              fetch(`${API_URL}/eventos/${evento.id}/curtidas/contagem`),
              fetch(`${API_URL}/eventos/${evento.id}/comentarios/contagem`),
            ]);

            const likesData = await likesRes.json();
            const commentsData = await commentsRes.json();

            return {
              ...evento,
              likes: likesData.totalCurtidas || 0,
              commentsCount: commentsData.totalComentarios || 0,
            };
          }),
        );

        setEventos(eventosComContagens);
        setTotalPaginas(eventosData.totalPaginas || 1);
      } catch (error) {
        console.error("Erro ao carregar eventos:", error);
        toast.error("Erro ao carregar eventos.");
      } finally {
        setCarregando(false);
      }
    };

    fetchData();
  }, [API_URL, pagina, initialLoading]);
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const dia = date.getDate();
    const mes = date.toLocaleString("pt-BR", { month: "short" }).toUpperCase();
    const ano = date.getFullYear();
    return { dia, mes, ano };
  };

  const toggleLike = async (evento: Evento) => {
    if (!userLocal?.id_func) {
      toast.warning("É preciso estar logado para curtir.");
      return;
    }

    const jaCurtiuLocal = likedEvents.includes(String(evento.id));

    try {
      if (!jaCurtiuLocal) {
        const response = await fetch(
          `${API_URL}/eventos/${evento.id}/curtidas`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id_func: userLocal.id_func,
              usuarioId: userLocal.id,
            }),
          },
        );

        if (!response.ok) {
          const erro = await response.json();
          toast.error(erro?.erro || "Erro ao curtir evento.");
          return;
        }

        setEventos((prev) =>
          prev.map((ev) =>
            ev.id === evento.id ? { ...ev, likes: ev.likes + 1 } : ev,
          ),
        );

        setLikedEvents((prev) => [...prev, String(evento.id)]);

        if (selectedEvent && selectedEvent.id === evento.id) {
          setSelectedEvent(
            (prev) => prev && { ...prev, likes: prev.likes + 1 },
          );
        }

        toast.success("Você curtiu o evento!");
      } else {
        const response = await fetch(
          `${API_URL}/eventos/${evento.id}/curtidas`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              id_func: userLocal.id_func,
              usuarioId: userLocal.id,
            }),
          },
        );

        if (!response.ok) {
          const erro = await response.json();
          toast.error(erro?.erro || "Erro ao remover curtida.");
          return;
        }

        setEventos((prev) =>
          prev.map((ev) =>
            ev.id === evento.id ? { ...ev, likes: ev.likes - 1 } : ev,
          ),
        );

        setLikedEvents((prev) => prev.filter((id) => id !== String(evento.id)));

        if (selectedEvent && selectedEvent.id === evento.id) {
          setSelectedEvent(
            (prev) => prev && { ...prev, likes: prev.likes - 1 },
          );
        }

        toast.info("Você removeu sua curtida do evento.");
      }
    } catch (error) {
      console.error("Erro ao curtir/descurtir evento:", error);
      toast.error("Ocorreu um erro. Tente novamente.");
    }
  };

  const handleCommentClick = (eventoId: string) => {
    if (showCommentBox === eventoId) {
      setShowCommentBox(null);
    } else {
      setShowCommentBox(eventoId);
    }
  };

  const handleAddComment = async (eventoId: string) => {
    if (!commentText.trim()) {
      toast.warning("O comentário não pode ser vazio.");
      return;
    }

    if (!userLocal?.id_func) {
      toast.warning("É preciso estar logado para comentar.");
      return;
    }

    try {
      const response = await fetch(
        `${API_URL}/eventos/${eventoId}/comentarios`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            id_func: userLocal.id_func,
            usuarioId: userLocal.id,
            comentario: commentText,
          }),
        },
      );

      if (!response.ok) {
        const erro = await response.json();
        toast.error(erro?.erro || "Erro ao adicionar comentário.");
        return;
      }

      const novoComentario: Comentario = {
        id: Date.now(),
        usuario_id: userLocal.id,
        comentario: commentText,
        createdAt: new Date().toISOString(),
        nome: userLocal.nome || "Você",
        avatar: userLocal.avatar || "default-avatar.jpg",
      };

      setComentariosModal((prev) => [...prev, novoComentario]);

      setEventos((prev) =>
        prev.map((ev) =>
          ev.id === eventoId
            ? { ...ev, commentsCount: (ev.commentsCount || 0) + 1 }
            : ev,
        ),
      );

      toast.success("Comentário adicionado com sucesso!");
      setCommentText("");
    } catch (error) {
      console.error("Erro ao adicionar comentário:", error);
      toast.error("Ocorreu um erro ao comentar. Tente novamente.");
    }
  };

  const handleVerMais = async (evento: Evento) => {
    try {
      const resp = await fetch(
        `${API_URL}/eventos/${evento.id}/comentarios?page=1&limit=50`,
      );
      const data = await resp.json();
      setComentariosModal(data?.comentarios || []);
    } catch (error) {
      console.error("Erro ao carregar comentários no modal:", error);
      toast.error("Não foi possível carregar os comentários do evento.");
    }
    setSelectedEvent(evento);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
    setComentariosModal([]);
  };

  const handlePaginaAnterior = () => {
    if (pagina > 1) {
      const novaPagina = pagina - 1;
      setPagina(novaPagina);
      navigate(`/pages/atualizacoes/listar-eventos?page=${novaPagina}`);
    }
  };

  const handleProximaPagina = () => {
    if (pagina < totalPaginas) {
      const novaPagina = pagina + 1;
      setPagina(novaPagina);
      navigate(`/pages/atualizacoes/listar-eventos?page=${novaPagina}`);
    }
  };

  const Comment = ({
    avatar,
    name,
    comment,
    date,
  }: {
    avatar: string;
    name: string;
    comment: string;
    date: string;
  }) => (
    <div className="d-flex" style={{ marginBottom: "0.3rem" }}>
      {" "}
      <Avatar size="xl" src={avatar || "default-avatar.jpg"} className="me-2" />
      <div>
        <div
          className="d-flex align-items-center"
          style={{ marginBottom: "0.1rem" }}
        >
          <strong className="me-2">{name}</strong>
          <small className="text-muted">
            {new Date(date).toLocaleString("pt-BR")}
          </small>
        </div>
        <p className="m-0" style={{ marginBottom: "0.1rem" }}>
          {comment}
        </p>{" "}
      </div>
    </div>
  );

  return (
    <>
      <Container className="mt-4">
        <ToastContainer />

        <PageBreadcrumb items={timelineBreadcrumb} />
        <h2 className="mb-4">Eventos</h2>

        {/* Mostrar spinner enquanto aguarda a validação inicial */}
        {initialLoading ? (
          <div className="text-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {carregando ? (
              <div className="text-center">
                <Spinner animation="border" />
              </div>
            ) : (
              <Row className="g-4">
                {eventos.map((evento) => {
                  if (!evento.titulo || !evento.descricao) {
                    console.warn("Evento com dados incompletos:", evento);
                    return null;
                  }

                  const imagemCompleta =
                    evento.imagem && evento.imagem.startsWith("http")
                      ? evento.imagem
                      : `${API_URL}/${evento.imagem || "default-image.jpg"}`;

                  const { dia, mes, ano } = formatDate(evento.createdAt);

                  const commentsCount = evento.commentsCount || 0;
                  const sharesCount = evento.sharesCount || 0;

                  return (
                    <Col key={evento.id} xs={12} md={6}>
                      <div className="card overflow-hidden">
                        <div className="card-body px-3 pb-2">
                          <div className="d-flex justify-content-between mb-2">
                            <div>
                              <h5 className="fw-bold mb-1">{evento.titulo}</h5>
                              <p
                                className="text-muted mb-0"
                                style={{ fontSize: "0.875rem" }}
                              >
                                Criado em: {dia} {mes} {ano}{" "}
                                <FontAwesomeIcon
                                  icon={faCircle}
                                  className="text-light ms-1 me-1"
                                  transform="shrink-10 down-2"
                                />
                              </p>
                            </div>

                            <div>
                              <Button onClick={() => handleVerMais(evento)}>
                                Ver mais
                              </Button>
                            </div>
                          </div>

                          {/* Descrição */}
                          <div className="bg-body-highlight border-top border-translucent p-2 text-muted mb-2">
                            <span
                              style={{
                                fontSize: "0.875rem",
                                display: "block",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {evento.descricao}
                            </span>
                          </div>

                          {/* Imagem */}
                          <div className="mb-2" style={{ borderRadius: 8 }}>
                            <img
                              src={imagemCompleta}
                              alt={evento.titulo}
                              style={{
                                width: "100%",
                                height: "300px",
                                objectFit: "cover",
                              }}
                            />
                          </div>

                          {/* Linha + infos de curtidas/comentários */}
                          <hr className="my-2" />
                          <div
                            className="d-flex justify-content-between align-items-center px-2"
                            style={{ fontSize: "0.875rem" }}
                          >
                            <div className="d-flex align-items-center text-muted">
                              <FontAwesomeIcon
                                icon={faHeart}
                                className={`me-1 ${
                                  likedEvents.includes(String(evento.id))
                                    ? "text-danger"
                                    : "text-muted"
                                }`}
                              />
                              <span>{evento.likes} curtidas</span>{" "}
                              {/* Aqui é exibido o número de curtidas */}
                            </div>

                            {/* Comentários e compartilhamentos */}
                            <div className="text-muted">
                              <span className="me-3">
                                {commentsCount} Comentários
                              </span>
                              <span>{sharesCount} Compartilhamentos</span>
                            </div>
                          </div>
                          <hr className="my-2" />

                          {/* Botões de Curtir, Comentar e Compartilhar */}
                          <div
                            className="d-flex justify-content-around"
                            style={{ fontSize: "0.875rem" }}
                          >
                            <Button
                              variant="link"
                              className="text-decoration-none text-muted"
                              onClick={() => toggleLike(evento)}
                            >
                              <FontAwesomeIcon
                                icon={faHeart}
                                className={`me-1 ${
                                  likedEvents.includes(String(evento.id))
                                    ? "text-danger"
                                    : "text-muted"
                                }`}
                              />
                              {likedEvents.includes(String(evento.id))
                                ? "Descurtir"
                                : "Curtir"}
                            </Button>

                            <Button
                              variant="link"
                              className="text-decoration-none text-muted"
                              onClick={() => handleCommentClick(evento.id)}
                            >
                              <FontAwesomeIcon
                                icon={faComment}
                                className="me-1"
                              />
                              Comentar
                            </Button>

                            <Button
                              variant="link"
                              className="text-decoration-none text-muted"
                            >
                              <FontAwesomeIcon
                                icon={faShare}
                                className="me-1"
                              />
                              Compartilhar
                            </Button>
                          </div>

                          {/* Caixa de Comentário Inline */}
                          {showCommentBox === evento.id && (
                            <div className="mt-3">
                              <div className="d-flex align-items-center">
                                <Form.Control
                                  type="text"
                                  className="form-control-sm"
                                  value={commentText}
                                  onChange={(e) =>
                                    setCommentText(e.target.value)
                                  }
                                  placeholder="Digite seu comentário..."
                                />

                                <Button
                                  variant="primary"
                                  className="ms-2 p-0 d-flex align-items-center justify-content-center"
                                  style={{
                                    height: "calc(1.5em + 0.75rem + 2px)",
                                    width: "2.5rem",
                                  }}
                                  onClick={() => {
                                    handleAddComment(evento.id);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faPaperPlane} />
                                </Button>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}

            {/* Paginação */}
            <div className="d-flex justify-content-center align-items-center mt-4">
              <CustomFooter
                currentPage={pagina}
                totalPages={totalPaginas}
                onPrevious={handlePaginaAnterior}
                onNext={handleProximaPagina}
                onPageChange={(page: React.SetStateAction<number>) =>
                  setPagina(page)
                }
              />
            </div>
          </>
        )}
      </Container>

      {/* Modal para mostrar evento + comentários completos */}
      <Modal show={showModal} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedEvent?.titulo}</Modal.Title>
        </Modal.Header>

        <Modal.Body style={{ paddingBottom: "0.5rem" }}>
          {selectedEvent && (
            <>
              {/* Imagem e descrição do evento */}
              <div
                style={{
                  borderRadius: 8,
                  marginBottom: "1rem",
                  textAlign: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={
                    selectedEvent.imagem &&
                    selectedEvent.imagem.startsWith("http")
                      ? selectedEvent.imagem
                      : `${API_URL}/${selectedEvent.imagem || "default-image.jpg"}`
                  }
                  alt={selectedEvent.titulo}
                  style={{
                    width: "100%",
                    height: "300px",
                    objectFit: "contain",
                    objectPosition: "center",
                  }}
                />
              </div>

              <p
                style={{
                  margin: "0 1.5rem",
                  padding: "0.5rem",
                  lineHeight: "1.6",
                  color: "#666",
                  backgroundColor: "#f9f9f9",
                  borderRadius: "8px",
                  display: "block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: expanded ? "normal" : "nowrap",
                  maxHeight: expanded ? "none" : "4.8rem",
                }}
              >
                {selectedEvent.descricao}
              </p>
              {selectedEvent.descricao.length > 200 && (
                <button
                  onClick={() => setExpanded((prev) => !prev)}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#007bff",
                    cursor: "pointer",
                    margin: "0.5rem 0 0 1.5rem",
                  }}
                >
                  {expanded ? "Mostrar menos" : "Ver mais"}
                </button>
              )}

              <hr />
              <div
                className="d-flex justify-content-between align-items-center px-3"
                style={{ fontSize: "0.875rem" }}
              >
                {/* Likes */}
                <div className="d-flex align-items-center text-muted">
                  <FontAwesomeIcon
                    icon={faHeart}
                    className={`me-1 ${likedEvents.includes(String(selectedEvent?.id)) ? "text-danger" : "text-muted"}`}
                  />
                  <span>{selectedEvent?.likes || 0} curtidas</span>
                </div>

                {/* Comentários e compartilhamentos */}
                <div className="text-muted">
                  <span className="me-3">
                    {selectedEvent.commentsCount || 0} Comentários
                  </span>
                  <span>
                    {selectedEvent.sharesCount || 0} Compartilhamentos
                  </span>
                </div>
              </div>

              <hr className="my-2" />
              <div
                className="d-flex justify-content-around"
                style={{ fontSize: "0.875rem" }}
              >
                <Button
                  variant="link"
                  className="text-decoration-none text-muted"
                  onClick={() => toggleLike(selectedEvent)}
                >
                  <FontAwesomeIcon
                    icon={faHeart}
                    className={`me-1 ${
                      likedEvents.includes(String(selectedEvent.id))
                        ? "text-danger"
                        : "text-muted"
                    }`}
                  />
                  {likedEvents.includes(String(selectedEvent.id))
                    ? "Descurtir"
                    : "Curtir"}
                </Button>

                <Button
                  variant="link"
                  className="text-decoration-none text-muted"
                  onClick={() =>
                    console.log("Abrir caixa de comentário do modal")
                  }
                >
                  <FontAwesomeIcon icon={faComment} className="me-1" />
                  Comentar
                </Button>

                <Button
                  variant="link"
                  className="text-decoration-none text-muted"
                >
                  <FontAwesomeIcon icon={faShare} className="me-1" />
                  Compartilhar
                </Button>
              </div>
              <div className="mt-3">
                <h5>Comentários</h5>
                <div
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                    padding: "0.5rem",
                  }}
                >
                  {comentariosModal.length === 0 ? (
                    <p className="text-muted">Nenhum comentário ainda.</p>
                  ) : (
                    comentariosModal.map((coment) => (
                      <div
                        key={coment.id}
                        style={{
                          padding: "0.2rem",
                          backgroundColor: "#f9f9f9",
                          borderRadius: "6px",
                          marginBottom: "0.3rem",
                          boxShadow: "0 0.5px 2px rgba(0, 0, 0, 0.1)",
                          fontSize: "0.75rem",
                          color: "#555",
                        }}
                      >
                        <Comment
                          avatar={coment.avatar}
                          name={coment.nome}
                          comment={coment.comentario}
                          date={coment.createdAt}
                        />
                      </div>
                    ))
                  )}
                </div>

                <div className="mt-3">
                  <div className="d-flex align-items-center">
                    <Form.Control
                      type="text"
                      className="form-control-sm"
                      value={commentText}
                      onChange={(e) => setCommentText(e.target.value)}
                      placeholder="Digite seu comentário..."
                      style={{
                        marginRight: "0.5rem",
                        borderRadius: "4px",
                      }}
                    />

                    <Button
                      variant="primary"
                      className="p-0 d-flex align-items-center justify-content-center"
                      style={{
                        height: "calc(1.5em + 0.75rem + 2px)",
                        width: "2.5rem",
                      }}
                      onClick={() => handleAddComment(selectedEvent?.id)}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ListarEventos;
