// SSI 0107 - GCOLLE - Adicionar Cadastro Squad - 03/02/2024
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Overlay,
  Popover,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import ReactSelect from "components/base/ReactSelect";
import UserContext from "components/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faMoneyCheck,
  faDollarSign,
  faUserTie,
  faUsers,
  faExclamationCircle,
  faShieldAlt, // Ícone do Squad
  faAddressCard,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FeatherIcon from "feather-icons-react";

interface ICondominio {
  chave: number;
  fantasia?: string;
  cidade?: string;
}

interface IUserAbsenceInfo {
  name: string;
  alertText?: string;
  originalNameForAbsence?: string;
  absenceEndDate?: string;
}

function normalizeString(str: string) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
}

// Agora adicionamos o Squad também:
const rolesMap = [
  {
    key: "SQUAD",
    label: "Squad",
    icon: <FontAwesomeIcon icon={faShieldAlt} className="me-2 fs-5" />,
  },
  {
    key: "ASSEMBLEIAS",
    label: "Assembleias",
    icon: <FontAwesomeIcon icon={faUsers} className="me-1 fs-5" />,
  },
  {
    key: "CADASTRO",
    label: "Cadastro",
    icon: <FontAwesomeIcon icon={faAddressCard} className="me-1 fs-5" />,
  },
  {
    key: "COBRANÇA",
    label: "Cobrança",
    icon: <FontAwesomeIcon icon={faMoneyCheck} className="me-1 fs-5" />,
  },
  {
    key: "COMUNICADOS_NOTIFICAÇÕES",
    label: "Consult./Notif",
    icon: <FontAwesomeIcon icon={faEnvelope} className="me-1 fs-5" />,
  },
  {
    key: "FINANCEIRO",
    label: "Financeiro",
    icon: <FontAwesomeIcon icon={faDollarSign} className="me-1 fs-5" />,
  },
  {
    key: "RH",
    label: "RH",
    icon: <FontAwesomeIcon icon={faUserTie} className="me-1 fs-5" />,
  },
];

const CondominioPesquisa: React.FC = () => {
  const { user } = useContext(UserContext);
  const API_URL = process.env.REACT_APP_API_URL;
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<ICondominio | null>(null);

  // Aqui armazenamos a informação de cada departamento/área (Financeiro, Cobrança, Squad, etc.)
  const [userNames, setUserNames] = useState<Record<string, IUserAbsenceInfo>>(
    {},
  );

  const [loadingCondominios, setLoadingCondominios] = useState(false);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSelect, setShowSelect] = useState(false);

  const selectRef = useRef<HTMLDivElement | null>(null);
  const [selectWidth, setSelectWidth] = useState<number | null>(null);

  // Ajusta o scroll da página para sempre ter scrollbar
  useEffect(() => {
    const htmlElement = document.documentElement;
    htmlElement.style.overflowY = "scroll";
    return () => {
      htmlElement.style.overflowY = "";
    };
  }, []);

  // Calcula a largura do select para usar no Popover
  useEffect(() => {
    if (selectRef.current) {
      setSelectWidth(selectRef.current.offsetWidth);
    }
  }, [showSelect, condominios]);

  // Carrega a lista de condomínios
  useEffect(() => {
    setLoadingCondominios(true);

    const url =
      user.usuario_cidade === "Sim"
        ? `${API_URL}/carteira-por-usuario/usuario-cidade/condominios-restritos/${user.id_func}`
        : `${API_URL}/condominios/comCidade`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const validCondominios = data.filter(
          (cond: ICondominio) => cond.fantasia && cond.cidade,
        );
        setCondominios(validCondominios);
        setErrorMessage("");
      })
      .finally(() => setLoadingCondominios(false));
  }, [API_URL, user]);

  // Ao selecionar o condomínio no ReactSelect
  const handleSelect = (selectedOption: any) => {
    if (!selectedOption) {
      setSelectedCondominio(null);
      return;
    }
    const cond = condominios.find((c) => c.chave === selectedOption.value);
    setSelectedCondominio(cond || null);
  };

  // Fechar o select
  const handleClose = () => {
    setShowSelect(false);
    setSelectedCondominio(null);
    setUserNames({});
    setErrorMessage("");
  };

  // Botão "Fechar" no popover
  const handleCloseButton = () => {
    setSelectedCondominio(null);
    setUserNames({});
    setErrorMessage("");
  };

  // Carrega os dados da carteira do condomínio selecionado
  // e converte para o state userNames
  useEffect(() => {
    const fetchUserData = async () => {
      if (!selectedCondominio) return;

      setLoadingUserData(true);
      try {
        // Busca os dados da carteira
        const res = await fetch(
          `${API_URL}/condominios/porId/${selectedCondominio.chave}`,
        );
        if (!res.ok) throw new Error("Erro ao buscar carteira.");
        const data = await res.json();

        // Exemplo: 'data' pode ter:
        // {
        //   "FINANCEIRO": 123,
        //   "COBRANÇA": 456,
        //   "RH": null,
        //   "nome_squad": "Squad Suporte",
        //   ...
        // }

        // 1. Montamos um objeto "newUserNames" que irá conter os nomes de cada área
        const newUserNames: Record<string, IUserAbsenceInfo> = {};

        // 2. Se a API já retorna "nome_squad", guardamos diretamente no userNames["SQUAD"]
        if (data.nome_squad) {
          newUserNames["SQUAD"] = {
            name: data.nome_squad,
          };
        }

        // 3. Para cada campo numérico (ex: FINANCEIRO: 123, COBRANÇA: 456 etc.)
        //    verificamos se existe no rolesMap e buscamos o nome do colaborador
        //    + ausência/redirecionamento
        for (const key of Object.keys(data)) {
          if (typeof data[key] === "number") {
            const idFunc = data[key];
            if (!idFunc) continue;

            let normalizedKey = normalizeString(key); // Mantém a chave original

            // Verifica se existe no rolesMap
            const roleMapping = rolesMap.find(
              (role) => normalizeString(role.key) === normalizedKey,
            );

            if (!roleMapping) continue;

            // Busca nome do colaborador
            try {
              const { name: originalName } = await getUserNameById(idFunc);
              // Verifica ausência
              const absenceInfo = await checkUserAbsence(
                idFunc,
                selectedCondominio.chave,
                originalName,
              );
              newUserNames[roleMapping.key] = absenceInfo;
            } catch (error) {
              console.error(`Erro ao buscar usuário para ID ${idFunc}`, error);
            }
          }
        }

        setUserNames(newUserNames);
      } catch (error) {
        console.error(error);
        setErrorMessage("Erro ao carregar dados do condomínio.");
      } finally {
        setLoadingUserData(false);
      }
    };

    if (selectedCondominio) {
      fetchUserData();
    } else {
      setUserNames({});
    }
  }, [selectedCondominio, API_URL]);

  // Função que busca nome do colaborador por ID
  async function getUserNameById(idFunc: number) {
    try {
      const resp = await fetch(`${API_URL}/user/get-user/${idFunc}`);
      const json = await resp.json();
      return {
        name: json?.user?.nome || `Usuário #${idFunc}`,
      };
    } catch {
      return { name: `#${idFunc}` };
    }
  }

  // Verifica se usuário está ausente e se há redirecionamento
  async function checkUserAbsence(
    idFunc: number,
    idCondo: number,
    originalName: string,
  ): Promise<IUserAbsenceInfo> {
    try {
      const resp = await fetch(
        `${API_URL}/ausencia/check-active-absences/${idFunc}`,
      );
      const data = await resp.json();
      const hasActiveAbsence = data && data.activeAbsencesCount > 0;

      if (hasActiveAbsence) {
        const redirResp = await fetch(
          `${API_URL}/ausencia/redirection-by-user/${idFunc}/${idCondo}`,
        );
        const redirData = await redirResp.json();

        if (redirData?.redirection) {
          const tempID = redirData.redirection.id_func_temp;
          const { name: tempName } = await getUserNameById(tempID);
          const dtFim = redirData.redirection.dt_final
            ? formatDate(redirData.redirection.dt_final)
            : "";
          return {
            name: tempName,
            alertText: "Cumprindo ausência",
            originalNameForAbsence: originalName,
            absenceEndDate: dtFim,
          };
        } else {
          return {
            name: originalName,
            alertText: "Ausente",
          };
        }
      }
      return { name: originalName };
    } catch (error) {
      return { name: originalName };
    }
  }

  // Formata data YYYY-MM-DD => DD/MM/YYYY
  function formatDate(isoDate: string) {
    const dataObj = new Date(isoDate);
    const dia = String(dataObj.getDate()).padStart(2, "0");
    const mes = String(dataObj.getMonth() + 1).padStart(2, "0");
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  // Converte para nome com inicial maiúscula
  function capitalizeName(name: string): string {
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <div className="p-2">
      {/* Seletor de Condomínio */}
      <div>
        {!showSelect ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="tooltip-pesquisar-condominio">
                Pesquisar condomínio
              </Tooltip>
            }
          >
            <div
              onClick={() => setShowSelect(true)}
              style={{ cursor: "pointer", display: "inline-block" }}
            >
              <FeatherIcon icon="credit-card" size={19} />
            </div>
          </OverlayTrigger>
        ) : (
          <div className="d-flex align-items-center gap-3">
            <div ref={selectRef}>
              <ReactSelect
                styles={{
                  control: (base: any) => ({
                    ...base,
                    width: "310px",
                  }),
                }}
                options={condominios.map((cond) => ({
                  value: cond.chave,
                  label: `${cond.fantasia} - ${cond.cidade}`,
                }))}
                closeMenuOnSelect={true}
                isClearable
                placeholder={
                  loadingCondominios ? "Carregando..." : "Condomínio"
                }
                isLoading={loadingCondominios}
                onChange={handleSelect}
              />
            </div>
            <FontAwesomeIcon
              icon={faTimes}
              className="cursor-pointer"
              onClick={handleClose}
            />
          </div>
        )}
      </div>

      {/* Popover com os detalhes do condomínio */}
      <Overlay
        target={selectRef.current}
        show={!!selectedCondominio}
        placement="bottom"
      >
        {(props) => (
          <Popover
            {...props}
            style={{
              ...props.style,
              zIndex: 9999,
              minWidth: "310px",
              maxWidth: "500px",
            }}
          >
            <Popover.Body>
              {selectedCondominio && (
                <>
                  {loadingUserData ? (
                    <div className="d-flex justify-content-center my-3">
                      <Spinner animation="border" role="status" size="sm">
                        <span className="visually-hidden">Carregando...</span>
                      </Spinner>
                    </div>
                  ) : (
                    <div className="d-flex flex-column gap-3 mb-3">
                      {rolesMap.map((role) => {
                        const userInfo = userNames[role.key];

                        return (
                          <div
                            key={role.key}
                            className="d-flex align-items-center"
                          >
                            <span
                              className="d-flex align-items-center justify-content-center me-3"
                              style={{ width: "30px", fontSize: "1.2em" }}
                            >
                              {role.icon}
                            </span>
                            <div className="d-flex flex-column text-start">
                              <span>
                                <span className="fw-bold">{role.label}:</span>{" "}
                                {userInfo
                                  ? capitalizeName(userInfo.name)
                                  : "Sem informação"}
                              </span>
                              {/* Exibe a badge se houver "alertText" (ausência, etc.) */}
                              {userInfo && userInfo.alertText && (
                                <span className="badge bg-warning mt-1">
                                  {userInfo.alertText}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <div className="d-flex justify-content-center">
                    <Button variant="secondary" onClick={handleCloseButton}>
                      Fechar
                    </Button>
                  </div>
                </>
              )}
            </Popover.Body>
          </Popover>
        )}
      </Overlay>

      {errorMessage && (
        <div className="alert alert-danger mt-3" role="alert">
          {errorMessage}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default CondominioPesquisa;
