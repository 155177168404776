// SSI 0085 - GCOLLE - Informações Usuários, Refazendo página para padrão novo - 10/01/2025
// SSI 0107 - GCOLLE - Adicionar Cadastro Squad - 03/02/2024
import React, { useEffect, useState, ChangeEvent, FC } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Row, Form } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";

import { UserCheck, UserX } from "feather-icons-react";

interface IUsuario {
  id_func: number;
  nome: string;
  area: string;
  situacao: string;
}

interface IFiltroUsuarios {
  nome?: string;
  area?: string;
  situacao?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Inativar Usuário", active: true },
];

const InativarUsuario = () => {
  const [usuarios, setUsuarios] = useState<IUsuario[]>([]);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [areasUnicas, setAreasUnicas] = useState<
    { value: string; label: string }[]
  >([]);
  const [filtro, setFiltro] = useState<IFiltroUsuarios>({});
  const [nomeBusca, setNomeBusca] = useState("");

  useEffect(() => {
    fetchUsuarios();
    fetchAreas();
  }, [order, filtro]);

  const styles = `
      .action-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .action-buttons .icon-button {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s;
      }
      .phoenix-table tbody tr:hover .icon-button {
        opacity: 1 !important;
        visibility: visible;
      }
    `;

  const fetchUsuarios = async () => {
    try {
      let url = `${API_URL}/inativar-usuario/usuarios/filtrar?order=${order}`;

      if (filtro.situacao) {
        url += `&situacao=${filtro.situacao}`;
      }

      const response = await axios.get<IUsuario[]>(url);

      const usuariosFiltrados = response.data.filter((usuario) => {
        const usuarioAreas = usuario.area
          .split(",")
          .map((a) => normalizeText(a.trim()));

        const matchesArea = filtro.area
          ? usuarioAreas.includes(normalizeText(filtro.area))
          : true;

        const matchesSituacao = filtro.situacao
          ? normalizeText(usuario.situacao) === normalizeText(filtro.situacao)
          : true;

        return matchesArea && matchesSituacao;
      });

      setUsuarios(usuariosFiltrados);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      toast.error("Erro ao buscar usuários");
    }
  };

  const fetchAreas = async () => {
    try {
      const response = await axios.get<{ area: string; ativa: boolean }[]>(
        `${API_URL}/usuarios/areas`,
      );

      const options = response.data.map((area) => ({
        value: normalizeText(area.area),
        label: area.area,
      }));

      setAreasUnicas(options);
    } catch (error) {
      console.error("Erro ao buscar áreas:", error);
      toast.error("Erro ao buscar áreas");
    }
  };

  const normalizeText = (text: string) =>
    text
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const filtrarAreas = (area: string) => {
    setFiltro((prev) => ({ ...prev, area }));
  };

  const filtrarSituacao = (situacao: string) => {
    setFiltro((prev) => ({ ...prev, situacao }));
  };

  const inativarUsuario = async (id_func: number) => {
    try {
      const pendenciaRes = await axios.get(
        `${API_URL}/inativar-usuario/usuarios/verificar-condominios/${id_func}`,
      );

      if (pendenciaRes.data.temPendencias) {
        toast.warn(
          "Usuário contém condomínios em seu nome, transfira-os antes de inativar.",
        );
        return;
      }

      const inativarRes = await axios.put(
        `${API_URL}/inativar-usuario/usuarios/inativar/${id_func}`,
      );
      toast.success(inativarRes.data);

      setUsuarios((prev) =>
        prev.map((usuario) =>
          usuario.id_func === id_func
            ? { ...usuario, situacao: "Inativo" }
            : usuario,
        ),
      );
    } catch (error) {
      console.error("Erro ao inativar o usuário:", error);
      toast.error("Erro ao inativar o usuário");
    }
  };

  const ativarUsuario = async (id_func: number) => {
    try {
      const ativarRes = await axios.put(
        `${API_URL}/inativar-usuario/usuarios/ativar/${id_func}`,
      );
      toast.success(ativarRes.data);

      setUsuarios((prev) =>
        prev.map((usuario) =>
          usuario.id_func === id_func
            ? { ...usuario, situacao: "Ativo" }
            : usuario,
        ),
      );
    } catch (error) {
      console.error("Erro ao ativar usuário:", error);
      toast.error("Erro ao ativar o usuário");
    }
  };

  const renderSituacaoBadge = (situacao: string) => {
    let variant: "success" | "warning" | "danger";
    let text: string;

    switch (situacao) {
      case "Ativo":
        variant = "success";
        text = "Ativo";
        break;
      case "Inativo":
        variant = "danger";
        text = "Inativo";
        break;
      default:
        variant = "warning";
        text = "Ausente";
        break;
    }

    return (
      <Badge
        variant="phoenix"
        bg={variant}
        className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
        style={{ minWidth: "80px" }}
      >
        {text}
      </Badge>
    );
  };

  const table = useAdvanceTable<IUsuario>({
    data: usuarios,
    columns: [
      {
        accessorKey: "id_func",
        header: "ID",
      },
      {
        accessorKey: "nome",
        header: "Nome",
      },
      {
        accessorKey: "area",
        header: "Área",
        cell: ({ row }: any) => {
          const usuario: IUsuario = row.original;
          return (
            <span>
              {usuario.area
                .split(",")
                .map((a) => a.trim())
                .join(", ")}
            </span>
          );
        },
      },
      {
        accessorKey: "situacao",
        header: "Situação",
        cell: ({ row }: any) => renderSituacaoBadge(row.original.situacao),
      },
      {
        id: "acao",
        header: "Ação",
        cell: ({ row }: any) => {
          const usuario: IUsuario = row.original;
          const handleButtonClick = () => {
            if (usuario.situacao === "Ausente") {
              toast.error("Ação não permitida: Usuário está ausente.");
              return;
            }

            if (usuario.situacao === "Inativo") {
              ativarUsuario(usuario.id_func);
            } else {
              inativarUsuario(usuario.id_func);
            }
          };

          return (
            <div className="d-flex align-items-center action-buttons">
              <button
                className={`icon-button btn btn-link ${
                  usuario.situacao === "Inativo"
                    ? "text-success"
                    : "text-danger"
                } p-0 m-0`}
                style={{ cursor: "pointer" }}
                onClick={handleButtonClick}
                title={
                  usuario.situacao === "Inativo"
                    ? "Clique para ativar o usuário"
                    : "Clique para inativar o usuário"
                }
              >
                {usuario.situacao === "Inativo" ? (
                  <UserCheck size={20} />
                ) : (
                  <UserX size={20} />
                )}
              </button>
            </div>
          );
        },
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "nome", desc: false }],
    },
  });

  return (
    <div>
      <style>{styles}</style>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <h3 className="mb-4">Lista de Usuários</h3>

      <AdvanceTableProvider {...table}>
        <Row className="g-3 mb-4 align-items-end">
          <Col xs={12} sm={4}>
            <Form.Group controlId="buscarUsuario">
              <Form.Label>Buscar Usuários</Form.Label>
              <SearchBox
                placeholder="Pesquisar usuário"
                onChange={handleSearchInputChange}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>
          <Col xs={12} sm={3}>
            <Form.Group controlId="filtrarArea">
              <Form.Label>Filtrar Área</Form.Label>
              <Form.Select
                value={filtro.area || ""}
                onChange={(e) => filtrarAreas(e.target.value)}
              >
                <option value="">Todas</option>
                {areasUnicas.map((area, index) => (
                  <option key={index} value={area.value}>
                    {area.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={12} sm={3}>
            <Form.Group controlId="filtrarSituacao">
              <Form.Label>Filtrar Situação</Form.Label>
              <Form.Select
                value={filtro.situacao || ""}
                onChange={(e) => filtrarSituacao(e.target.value)}
              >
                <option value="">Todas</option>
                <option value="Ativo">Ativo</option>
                <option value="Inativo">Inativo</option>
                <option value="Ausente">Ausente</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>

        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{ className: "phoenix-table fs-9 table-hover" }}
          />
        </div>
        <AdvanceTableFooter pagination />
      </AdvanceTableProvider>
    </div>
  );
};

export default InativarUsuario;
