// SSI 0083 - GCOLLE - Refazendo com padrão Phoenix Páginas Carteiras - 09/01/2025
// SSI 0111 - GCOLLE - Adicionando editar carteira para ter CADASTRO, e modificando lógica squad para apenas verificar o RH e Financeiro - 06/02/2025
import React, { useState, useEffect } from "react";
import Button from "components/base/Button";
import ReactSelect from "components/base/ReactSelect";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import { Card, Col, Form, Row, Modal, ListGroup } from "react-bootstrap";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ICondominio {
  chave: number;
  razao: string;
  cidade?: string;
  uf?: string;
}

interface IUsuario {
  id_func: number;
  nome: string;
}

interface ISelectOption<T> {
  value: T;
  label: string;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Editar Carteira", active: true },
];

const EditarCarteira = () => {
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const [selectedCondominio, setSelectedCondominio] =
    useState<ICondominio | null>(null);

  // Listas de usuários
  const [usuariosFinanceiro, setUsuariosFinanceiro] = useState<IUsuario[]>([]);
  const [usuariosCobranca, setUsuariosCobranca] = useState<IUsuario[]>([]);
  const [usuariosRH, setUsuariosRH] = useState<IUsuario[]>([]);
  const [usuariosComunicados, setUsuariosComunicados] = useState<IUsuario[]>(
    [],
  );
  const [usuariosAssembleias, setUsuariosAssembleias] = useState<IUsuario[]>(
    [],
  );
  const [usuariosCadastro, setUsuariosCadastro] = useState<IUsuario[]>([]);

  // Selecionados
  const [selectedFinanceiro, setSelectedFinanceiro] = useState<IUsuario | null>(
    null,
  );
  const [selectedCobranca, setSelectedCobranca] = useState<IUsuario | null>(
    null,
  );
  const [selectedRH, setSelectedRH] = useState<IUsuario | null>(null);
  const [selectedComunicados, setSelectedComunicados] =
    useState<IUsuario | null>(null);
  const [selectedAssembleias, setSelectedAssembleias] =
    useState<IUsuario | null>(null);
  const [selectedCadastro, setSelectedCadastro] = useState<IUsuario | null>(
    null,
  );

  // Pesos
  const [pesoFinanceiro, setPesoFinanceiro] = useState("");
  const [pesoCobranca, setPesoCobranca] = useState("");
  const [pesoRH, setPesoRH] = useState("");
  const [pesoComunicados, setPesoComunicados] = useState("");
  const [pesoAssembleias, setPesoAssembleias] = useState("");
  const [pesoCadastro, setPesoCadastro] = useState("");

  // Outros campos
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [possuiAgendamento, setPossuiAgendamento] = useState("");
  const [missingCondosData, setMissingCondosData] = useState<{
    missingCondos: ICondominio[];
    totalDimCondos: number;
    totalEuroCarteiras: number;
  }>({
    missingCondos: [],
    totalDimCondos: 0,
    totalEuroCarteiras: 0,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [carteiraData, setCarteiraData] = useState<any>({});
  const [idPholha, setIdPholha] = useState("");
  const [diaPagamentoSalarios, setDiaPagamentoSalarios] = useState("");
  const [diaPagamentoAdiantamento, setDiaPagamentoAdiantamento] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleVisibility = () => {
    setIsExpanded(!isExpanded);
  };

  const API_URL = process.env.REACT_APP_API_URL;

  // Busca inicial de condomínios e usuários
  useEffect(() => {
    fetch(`${API_URL}/editar-carteira/buscar-condominios`)
      .then((res) => {
        if (!res.ok) throw new Error("Erro ao buscar condomínios");
        return res.json();
      })
      .then((data) => {
        setCondominios(data);
      })
      .catch((err) => console.error("Erro:", err));

    // Buscar cada área (ajuste conforme o backend espera a string de área)
    getUsuariosByArea("Financeiro").then((data) => setUsuariosFinanceiro(data));
    getUsuariosByArea("Cobrança").then((data) => setUsuariosCobranca(data));
    getUsuariosByArea("RH").then((data) => setUsuariosRH(data));
    getUsuariosByArea("Juridico").then((data) => setUsuariosComunicados(data));
    getUsuariosByArea("Assembleias").then((data) =>
      setUsuariosAssembleias(data),
    );

    // Buscar usuários para "CADASTRO" (caso o backend tenha essa área)
    getUsuariosByArea("Cadastro").then((data) => setUsuariosCadastro(data));

    // Buscar missingCondos
    fetch(`${API_URL}/editar-carteira/buscar-condominios-faltantes`)
      .then((res) => res.json())
      .then((data) => {
        if (data && data.missingCondos) {
          setMissingCondosData({
            ...data,
            missingCondos: (data.missingCondos || []).filter(
              (condo: ICondominio) => condo.chave > 606,
            ),
          });
        }
      })
      .catch((err) => console.error("Erro missing condos:", err));
  }, [API_URL]);

  // Função que busca usuários por área
  const getUsuariosByArea = async (area: string): Promise<IUsuario[]> => {
    try {
      const res = await fetch(
        `${API_URL}/editar-carteira/buscar-usuarios-por-area/${area}`,
      );
      if (!res.ok) throw new Error("Erro ao buscar usuários");
      return res.json();
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
      return [];
    }
  };

  // useEffect: Detalhes da Carteira (ao selecionar um condomínio)
  // SSI 0111 - GCOLLE - 06/02/2025
  useEffect(() => {
    if (!selectedCondominio) return;

    // console.log("🏢 Condomínio selecionado:", selectedCondominio);

    fetch(
      `${API_URL}/editar-carteira/buscar-detalhes-carteira/${selectedCondominio.chave}`,
    )
      .then((res) => {
        if (!res.ok) throw new Error("Carteira não encontrada");
        return res.json();
      })
      .then((data) => {
        if (!Object.keys(data).length) {
          console.warn("⚠️ Nenhum dado encontrado para esse condomínio!");
          resetFields();
          return;
        }

        // console.log("📦 Dados da carteira retornados:", data);

        // Define diretamente os responsáveis, sem necessidade de busca no array
        setSelectedFinanceiro(
          data.FINANCEIRO
            ? { id_func: data.FINANCEIRO, nome: data.nomeFinanceiro }
            : null,
        );
        setSelectedCobranca(
          data.COBRANÇA
            ? { id_func: data.COBRANÇA, nome: data.nomeCobranca }
            : null,
        );
        setSelectedRH(data.RH ? { id_func: data.RH, nome: data.nomeRH } : null);
        setSelectedComunicados(
          data.COMUNICADOS_NOTIFICAÇÕES
            ? {
                id_func: data.COMUNICADOS_NOTIFICAÇÕES,
                nome: data.nomeComunicados,
              }
            : null,
        );
        setSelectedAssembleias(
          data.ASSEMBLEIAS
            ? { id_func: data.ASSEMBLEIAS, nome: data.nomeAssembleias }
            : null,
        );
        setSelectedCadastro(
          data.CADASTRO
            ? { id_func: data.CADASTRO, nome: data.nomeCadastro }
            : null,
        );

        // Define demais estados diretamente
        setCidade(data.CIDADE ?? "");
        setEstado(data.ESTADO ?? "");
        setPossuiAgendamento(data.possui_agendamento ? "1" : "0");

        setPesoFinanceiro(data.peso_financeiro?.toString() ?? "");
        setPesoCobranca(data.peso_cobranca?.toString() ?? "");
        setPesoRH(data.peso_rh?.toString() ?? "");
        setPesoComunicados(data.peso_comunicados?.toString() ?? "");
        setPesoAssembleias(data.peso_assembleias?.toString() ?? "");
        setPesoCadastro(data.peso_cadastro?.toString() ?? "");

        setIdPholha(data.id_pholha ?? "");
        setDiaPagamentoSalarios(data.dia_util_salario?.toString() ?? "");
        setDiaPagamentoAdiantamento(data.dia_adiantamento?.toString() ?? "");
      })
      .catch((err) => {
        console.error("❌ Erro ao buscar detalhes da carteira:", err);
        resetFields();
      });
  }, [selectedCondominio, API_URL]);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  const handleCondoSelect = (condo: ICondominio | null) => {
    resetFields(); // Limpa os campos antes de carregar o novo condomínio
    if (condo) {
      setSelectedCondominio(condo);
      setCidade(condo.cidade || "");
      setEstado(condo.uf || "");
    }
  };

  const resetFields = () => {
    setSelectedCondominio(null);
    setSelectedFinanceiro(null);
    setSelectedCobranca(null);
    setSelectedRH(null);
    setSelectedComunicados(null);
    setSelectedAssembleias(null);
    setSelectedCadastro(null);

    setCidade("");
    setEstado("");
    setPossuiAgendamento("");
    setPesoFinanceiro("");
    setPesoCobranca("");
    setPesoRH("");
    setPesoComunicados("");
    setPesoAssembleias("");
    setPesoCadastro("");

    setCarteiraData({});
    setIdPholha("");
    setDiaPagamentoSalarios("");
    setDiaPagamentoAdiantamento("");
  };

  const handleSalvar = () => {
    const bodyObj = {
      ...carteiraData,
      CONDOMINIO: selectedCondominio?.razao || "",
      FINANCEIRO: selectedFinanceiro?.id_func || null,
      COBRANÇA: selectedCobranca?.id_func || null,
      RH: selectedRH?.id_func || null,
      COMUNICADOS_NOTIFICAÇÕES: selectedComunicados?.id_func || null,
      ASSEMBLEIAS: selectedAssembleias?.id_func || null,
      CADASTRO: selectedCadastro?.id_func || null,

      CIDADE: cidade,
      ESTADO: estado,
      id_cond: selectedCondominio?.chave || null,
      peso_financeiro: pesoFinanceiro,
      peso_cobranca: pesoCobranca,
      peso_rh: pesoRH,
      peso_comunicados: pesoComunicados,
      peso_assembleias: pesoAssembleias,
      peso_cadastro: pesoCadastro,

      possui_agendamento: Number(possuiAgendamento) === 1 ? 1 : 0,
      id_pholha: idPholha,
      dia_util_salario: diaPagamentoSalarios,
      dia_adiantamento: diaPagamentoAdiantamento,
    };

    fetch(`${API_URL}/editar-carteira/criar-ou-atualizar-carteira`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(bodyObj),
    })
      .then((res) => {
        if (!res.ok) {
          return res.text().then((text) => {
            throw new Error(text);
          });
        }
        return res.json();
      })
      .then((data) => {
        if (data.success) {
          toast.success(data.message);
          resetFields();
        } else {
          toast.error("Erro ao salvar a carteira.");
        }
      })
      .catch((err) => {
        console.error("❌ Erro ao salvar a carteira:", err);
      });
  };

  const estadosBrasil = [
    "Acre",
    "Alagoas",
    "Amapá",
    "Amazonas",
    "Bahia",
    "Ceará",
    "Distrito Federal",
    "Espírito Santo",
    "Goiás",
    "Maranhão",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Minas Gerais",
    "Pará",
    "Paraíba",
    "Paraná",
    "Pernambuco",
    "Piauí",
    "Rio de Janeiro",
    "Rio Grande do Norte",
    "Rio Grande do Sul",
    "Rondônia",
    "Roraima",
    "Santa Catarina",
    "São Paulo",
    "Sergipe",
    "Tocantins",
  ];

  return (
    <div>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />
      <h2 className="mb-4">Editar Carteira</h2>

      <Row>
        {/* Coluna principal (campos de condomínio) */}
        <Col xs={12} xl={8}>
          <Row as="form" className="g-3 mb-6">
            {/* 1) BOTÃO PENDÊNCIAS */}
            <Col xs={12}>
              <div className="d-flex align-items-center">
                <div
                  style={{
                    cursor:
                      missingCondosData?.missingCondos?.length > 0
                        ? "pointer"
                        : "default",
                    visibility:
                      missingCondosData?.missingCondos?.length > 0
                        ? "visible"
                        : "hidden",
                  }}
                  onClick={
                    missingCondosData?.missingCondos?.length > 0
                      ? handleModalOpen
                      : undefined
                  }
                  className="badge badge-phoenix badge-phoenix-warning fs-12 d-inline-flex align-items-center py-2 px-3"
                >
                  🚨 {missingCondosData.missingCondos.length} cadastros
                  pendentes
                </div>
              </div>
            </Col>

            {/* 2) SELECIONAR CONDOMÍNIO */}
            <Col xs={12}>
              <Form.Label>Selecionar um Condomínio</Form.Label>
              <ReactSelect
                placeholder="Selecione o condomínio"
                options={condominios.map((cond) => ({
                  value: cond,
                  label: `${cond.razao} - ${cond.chave}`,
                }))}
                value={
                  selectedCondominio
                    ? {
                        value: selectedCondominio,
                        label: `${selectedCondominio.razao} - ${selectedCondominio.chave}`,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<ICondominio> | null;
                  handleCondoSelect(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
                isClearable
              />
            </Col>

            {/* 3) FINANCEIRO, AGENDAMENTO, PESO FINANCEIRO */}
            <Col sm={6} md={4}>
              <Form.Label>Responsável Financeiro</Form.Label>
              <ReactSelect
                placeholder="Selecione Financeiro"
                options={usuariosFinanceiro.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedFinanceiro
                    ? {
                        value: selectedFinanceiro,
                        label: selectedFinanceiro.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedFinanceiro(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Possui Agendamento?</Form.Label>
              <Form.Select
                value={possuiAgendamento}
                onChange={(e) => setPossuiAgendamento(e.target.value)}
              >
                <option value="">Selecione...</option>
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </Form.Select>
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Financeiro</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoFinanceiro}
                onChange={(e) => setPesoFinanceiro(e.target.value)}
              />
            </Col>

            {/* 4) COBRANÇA, PESO COBRANÇA, RH */}
            <Col sm={6} md={8}>
              <Form.Label>Responsável Cobrança</Form.Label>
              <ReactSelect
                placeholder="Selecione Cobrança"
                options={usuariosCobranca.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedCobranca
                    ? { value: selectedCobranca, label: selectedCobranca.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedCobranca(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Cobrança</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoCobranca}
                onChange={(e) => setPesoCobranca(e.target.value)}
              />
            </Col>
            <Col sm={6} md={8}>
              <Form.Label>Responsável RH</Form.Label>
              <ReactSelect
                placeholder="Selecione RH"
                options={usuariosRH.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedRH
                    ? { value: selectedRH, label: selectedRH.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedRH(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso RH</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoRH}
                onChange={(e) => setPesoRH(e.target.value)}
              />
            </Col>

            {/* 5) ASSEMBLEIAS, PESO ASSEMBLEIAS */}
            <Col sm={6} md={8}>
              <Form.Label>Responsável Assembleias</Form.Label>
              <ReactSelect
                placeholder="Selecione Comunicados"
                options={usuariosComunicados.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedComunicados
                    ? {
                        value: selectedComunicados,
                        label: selectedComunicados.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedComunicados(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Assembleias</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoAssembleias}
                onChange={(e) => setPesoAssembleias(e.target.value)}
              />
            </Col>

            {/* 6) COMUNICADOS, PESO COMUNICADOS */}
            <Col sm={6} md={8}>
              <Form.Label>Responsável Consultoria/Notificações</Form.Label>
              <ReactSelect
                placeholder="Selecione Assembleias"
                options={usuariosAssembleias.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedAssembleias
                    ? {
                        value: selectedAssembleias,
                        label: selectedAssembleias.nome,
                      }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedAssembleias(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Comunicados</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoComunicados}
                onChange={(e) => setPesoComunicados(e.target.value)}
              />
            </Col>

            {/* NOVO CAMPO: CADASTRO e PESO CADASTRO */}
            {/* SSI 0111 - GCOLLE - 06/02/2025 */}
            <Col sm={6} md={8}>
              <Form.Label>Responsável Cadastro</Form.Label>
              <ReactSelect
                placeholder="Selecione Cadastro"
                options={usuariosCadastro.map((u) => ({
                  value: u,
                  label: u.nome,
                }))}
                value={
                  selectedCadastro
                    ? { value: selectedCadastro, label: selectedCadastro.nome }
                    : null
                }
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<IUsuario> | null;
                  setSelectedCadastro(option ? option.value : null);
                }}
                closeMenuOnSelect={true}
              />
            </Col>
            <Col sm={6} md={4}>
              <Form.Label>Peso Cadastro</Form.Label>
              <Form.Control
                placeholder="Peso"
                type="number"
                min="0"
                max="10"
                value={pesoCadastro}
                onChange={(e) => setPesoCadastro(e.target.value)}
              />
            </Col>

            {/* 7) CIDADE, ESTADO */}
            <Col sm={6} md={6}>
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                placeholder="Cidade"
                type="text"
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
              />
            </Col>
            <Col sm={6} md={6}>
              <Form.Label>Estado</Form.Label>
              <ReactSelect
                placeholder="Selecione o Estado"
                options={estadosBrasil.map((uf) => ({
                  value: uf,
                  label: uf,
                }))}
                value={estado ? { value: estado, label: estado } : null}
                onChange={(newValue) => {
                  const option = newValue as ISelectOption<string> | null;
                  setEstado(option ? option.value : "");
                }}
                closeMenuOnSelect={true}
              />
            </Col>

            {/* 8) BOTÕES */}
            <Col xs={12} className="gy-6 mt-3">
              <div className="d-flex justify-content-end gap-3">
                <Button
                  variant="phoenix-primary"
                  className="px-5"
                  onClick={resetFields}
                >
                  Cancelar
                </Button>
                <Button
                  variant="primary"
                  className="px-5 px-sm-15"
                  onClick={handleSalvar}
                >
                  Salvar
                </Button>
              </div>
            </Col>
          </Row>
        </Col>

        {/* Coluna "Configurações Adicionais" (expand/collapse) */}
        <Col xs={12} xl={4} className="mt-9">
          <Row className="g-2">
            <Col xs={12} xl={12}>
              <Card>
                <Card.Header
                  style={{ cursor: "pointer" }}
                  onClick={toggleVisibility}
                  className="d-flex align-items-center justify-content-between"
                >
                  <h4 className="mb-0">Configurações Adicionais</h4>
                  <FontAwesomeIcon
                    icon={isExpanded ? faAngleUp : faAngleDown}
                    className="fs-9 text-900"
                  />
                </Card.Header>
                {isExpanded && (
                  <Card.Body>
                    <Row className="gx-3 gy-3">
                      {/* Campo: ID Pholha */}
                      <Col xs={12}>
                        <Form.Label>ID Pholha</Form.Label>
                        <Form.Control
                          placeholder="ID Pholha"
                          type="text"
                          value={idPholha}
                          onChange={(e) => setIdPholha(e.target.value)}
                        />
                      </Col>

                      {/* Campo: Dia de Pagamento Salários */}
                      <Col xs={12}>
                        <Form.Label>Dia de Pagamento Salários</Form.Label>
                        <Form.Select
                          value={diaPagamentoSalarios}
                          onChange={(e) =>
                            setDiaPagamentoSalarios(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Selecione...
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                        </Form.Select>
                      </Col>

                      {/* Campo: Dia de Pagamento Adiantamento */}
                      <Col xs={12}>
                        <Form.Label>Dia de Pagamento Adiantamento</Form.Label>
                        <Form.Select
                          value={diaPagamentoAdiantamento}
                          onChange={(e) =>
                            setDiaPagamentoAdiantamento(e.target.value)
                          }
                        >
                          <option disabled value="">
                            Selecione...
                          </option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                        </Form.Select>
                      </Col>
                    </Row>
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* Modal dos condominios pendentes*/}
      <Modal show={modalOpen} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Condomínios Pendentes</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflowY: "auto" }}>
          {missingCondosData.missingCondos.length > 0 ? (
            <ListGroup>
              {missingCondosData.missingCondos.map((condo) => (
                <ListGroup.Item
                  key={condo.chave}
                  action
                  onClick={() => {
                    handleCondoSelect(condo); // Limpa e carrega o novo condomínio
                    handleModalClose(); // Fecha o modal
                  }}
                >
                  {condo.razao} - (ID: {condo.chave})
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : (
            <p>Nenhum condomínio pendente encontrado.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EditarCarteira;
