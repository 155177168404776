//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
import React, { useState, useEffect, useContext } from "react";
import Button from "components/base/Button";
import {
  Col,
  Row,
  Form,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  Calendar,
} from "feather-icons-react";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import SearchBox from "components/common/SearchBox";
import ReactSelect from "components/base/ReactSelect";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import UserContext from "../../../../components/UserContext";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br"; // Importa o idioma português

interface IUsuarioOption {
  value: string;
  label: string;
}

interface IHistorico {
  condominio: string;
  reinf_enviado: boolean;
  leitura_enviado: boolean;
  consumos_individuais_enviado: boolean;
  reservas_enviado: boolean;
  balancetes_enviado: boolean;
  boleto_enviado: boolean;
  reinf_data_envio?: string;
  leitura_data_envio?: string;
  consumos_individuais_data_envio?: string;
  reservas_data_envio?: string;
  balancetes_data_envio?: string;
  boleto_data_envio?: string;
}

interface ICondominioDados {
  id: number;
  id_cond: number;
  fantasia: string; // nome do condomínio
  historico: IHistorico;
}

const UsuarioTable: React.FC = () => {
  // ---------------------------------------------------------------------------------
  // CONTEXT & STATES
  // ---------------------------------------------------------------------------------
  const { user } = useContext(UserContext);
  const isGestorOuAdmin = user?.funcao === "admin" || user?.funcao === "Gestor";

  const userId = user?.userId;
  const podeSelecionarOutros = [1, 27].includes(userId);

  const [mes, setMes] = useState<number>(new Date().getMonth() + 1);
  const [ano, setAno] = useState<number>(new Date().getFullYear());
  const [usuarios, setUsuarios] = useState<IUsuarioOption[]>([]);
  const [selectedUsuario, setSelectedUsuario] = useState<any>(
    podeSelecionarOutros
      ? null // Se for admin, começa sem seleção
      : { value: userId, label: user?.nome }, // Se for outro user, já travamos
  );
  const [selecionarTodos, setSelecionarTodos] = useState<boolean>(false);

  const [dadosFechamento, setDadosFechamento] = useState<ICondominioDados[]>(
    [],
  );
  const [dadosOrdenados, setDadosOrdenados] = useState<ICondominioDados[]>([]);
  const [carregando, setCarregando] = useState<boolean>(false);

  // Controle de Filtro/Ordenação
  const [filtroAtual, setFiltroAtual] = useState<{
    coluna: string;
    direcao: string;
  }>({
    coluna: "",
    direcao: "",
  });

  // ---------------------------------------------------------------------------------
  // MAPEAMENTO DE CAMPOS
  // ---------------------------------------------------------------------------------
  const fieldMapping: Record<string, keyof IHistorico> = {
    reinf: "reinf_enviado",
    leitura: "leitura_enviado",
    consumosIndividuais: "consumos_individuais_enviado",
    reservas: "reservas_enviado",
    balancetes: "balancetes_enviado",
    boleto: "boleto_enviado",
  };

  const calcularChecks = (campo: string): number => {
    const columnName = fieldMapping[campo];
    if (!columnName) return 0;
    return dadosFechamento.reduce(
      (acc, item) => (item.historico[columnName] ? acc + 1 : acc),
      0,
    );
  };

  // ---------------------------------------------------------------------------------
  // CONFIG DO ADVANCE TABLE (CABEÇALHO, COLUNAS, ETC.)
  // ---------------------------------------------------------------------------------
  const table = useAdvanceTable({
    data: dadosOrdenados,
    columns: [
      {
        accessorKey: "fantasia",
        header: "Condomínio",
      },
      {
        accessorKey: "reinf",
        header: () => (
          <div>
            <div>ReInf</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("reinf")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.reinf_enviado;
          const dataEnvio = item.historico?.reinf_data_envio || "";
          return renderCheckCell(item.id, "reinf", checked, dataEnvio);
        },
      },
      {
        accessorKey: "leitura",
        header: () => (
          <div>
            <div>Leitura</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("leitura")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.leitura_enviado;
          const dataEnvio = item.historico?.leitura_data_envio || "";
          return renderCheckCell(item.id, "leitura", checked, dataEnvio);
        },
      },
      {
        accessorKey: "consumosIndividuais",
        header: () => (
          <div>
            <div>Consumos Ind.</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("consumosIndividuais")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.consumos_individuais_enviado;
          const dataEnvio =
            item.historico?.consumos_individuais_data_envio || "";
          return renderCheckCell(
            item.id,
            "consumosIndividuais",
            checked,
            dataEnvio,
          );
        },
      },
      {
        accessorKey: "reservas",
        header: () => (
          <div>
            <div>Reservas</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("reservas")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.reservas_enviado;
          const dataEnvio = item.historico?.reservas_data_envio || "";
          return renderCheckCell(item.id, "reservas", checked, dataEnvio);
        },
      },
      {
        accessorKey: "balancetes",
        header: () => (
          <div>
            <div>Balancetes</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("balancetes")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.balancetes_enviado;
          const dataEnvio = item.historico?.balancetes_data_envio || "";
          return renderCheckCell(item.id, "balancetes", checked, dataEnvio);
        },
      },
      {
        accessorKey: "boleto",
        header: () => (
          <div>
            <div>Boleto</div>
            <div style={{ fontSize: "12px", color: "gray" }}>
              {calcularChecks("boleto")}/{dadosFechamento.length}
            </div>
          </div>
        ),
        cell: ({ row }) => {
          const item = row.original;
          const checked = item.historico?.boleto_enviado;
          const dataEnvio = item.historico?.boleto_data_envio || "";
          return renderCheckCell(item.id, "boleto", checked, dataEnvio);
        },
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
  });

  // ---------------------------------------------------------------------------------
  // HOOKS: OBTENDO USUÁRIOS E DADOS
  // ---------------------------------------------------------------------------------
  const API_URL = process.env.REACT_APP_API_URL;

  // Buscar lista de usuários ao carregar
  useEffect(() => {
    if (isGestorOuAdmin) {
      fetchUsuarios();
    } else if (user) {
      // Se não for admin/gestor, fixa o usuário atual
      setSelectedUsuario({ value: user.id_func, label: user.nome });
      setSelecionarTodos(false);
    }
  }, [user]);

  const fetchUsuarios = async () => {
    try {
      const response = await fetch(`${API_URL}/fechamento-mensal/usuarios`);
      const data = await response.json();
      // Cria opção "todos"
      const todosOption = { value: "todos", label: "Todos" };
      const options: IUsuarioOption[] = data.map((u: any) => ({
        value: u.id_func,
        label: u.nome,
      }));
      setUsuarios([todosOption, ...options]);

      // Define "todos" como usuário selecionado inicialmente
      setSelectedUsuario(todosOption);
      setSelecionarTodos(false);
    } catch (error) {
      console.error("Erro ao buscar usuários:", error);
    }
  };

  // ---------------------------------------------------------------------------------
  // BUSCAR DADOS DE FECHAMENTO
  // ---------------------------------------------------------------------------------
  const handleBuscarDados = async () => {
    // Verificações básicas
    if (!mes || !ano) {
      toast.error("Selecione mês e ano.");
      return;
    }
    if (!selecionarTodos && (!selectedUsuario || !selectedUsuario.value)) {
      toast.error("Selecione um usuário ou 'Todos'.");
      return;
    }

    setCarregando(true);
    setDadosFechamento([]);
    setDadosOrdenados([]);

    try {
      // 1) Buscar condomínios
      const usuarioId = selecionarTodos ? "todos" : selectedUsuario?.value;
      const url =
        usuarioId === "todos"
          ? `${API_URL}/fechamento-mensal/condominios`
          : `${API_URL}/fechamento-mensal/condominios/${usuarioId}`;

      const resp = await fetch(url);
      const condominios = await resp.json();

      // 2) IDs para consulta em lote
      const ids = condominios.map((cond: any) => cond.id);

      // 3) Chamar rota em lote
      const loteUrl = `${API_URL}/fechamento-mensal/historico-condominios/lote`;
      const loteResp = await fetch(loteUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ids, ano, mes }),
      });
      const historicoMap = await loteResp.json();

      // 4) Montar objeto final (unindo condomínios + histórico)
      const todosProcessados = condominios.map((cond: any) => {
        const histo = historicoMap[cond.id] || {};
        return {
          id: cond.id,
          id_cond: cond.id_cond,
          fantasia: cond.fantasia || "Desconhecido",
          historico: adaptHistorico({ registro: histo }),
        };
      });

      setDadosFechamento(todosProcessados);
      setCarregando(false);
    } catch (error) {
      console.error("Erro ao buscar dados do usuário:", error);
      setCarregando(false);
    }
  };

  // Ajusta o objeto retornado pela API para booleanos e datas
  const adaptHistorico = (histo: any) => {
    const r = histo.registro || histo;
    return {
      reinf_enviado: !!r.reinf_enviado,
      leitura_enviado: !!r.leitura_enviado,
      consumos_individuais_enviado: !!r.consumos_individuais_enviado,
      reservas_enviado: !!r.reservas_enviado,
      balancetes_enviado: !!r.balancetes_enviado,
      boleto_enviado: !!r.boleto_enviado,
      reinf_data_envio: r.reinf_data_envio || null,
      leitura_data_envio: r.leitura_data_envio || null,
      consumos_individuais_data_envio:
        r.consumos_individuais_data_envio || null,
      reservas_data_envio: r.reservas_data_envio || null,
      balancetes_data_envio: r.balancetes_data_envio || null,
      boleto_data_envio: r.boleto_data_envio || null,
    };
  };

  // ---------------------------------------------------------------------------------
  // AUTOMATIZAR BUSCA AO ALTERAR DATA OU USUÁRIO
  // ---------------------------------------------------------------------------------
  useEffect(() => {
    // Apenas chama handleBuscarDados se selectedUsuario estiver definido
    if (selectedUsuario) {
      handleBuscarDados();
    }
  }, [selectedUsuario, mes, ano]);

  // ---------------------------------------------------------------------------------
  // CHECKBOX E ATUALIZAÇÕES
  // ---------------------------------------------------------------------------------
  const handleCheckboxChange = async (
    condominioId: number,
    fieldName: string,
    isChecked: boolean,
  ) => {
    // Map de string -> campo do objeto 'historico'
    const columnName = fieldMapping[fieldName];
    if (!columnName) {
      console.error(`Campo inválido: ${fieldName}`);
      return;
    }

    // Montar o corpo da requisição
    const requestBody = {
      item: columnName,
      isChecked: isChecked ? 1 : 0,
    };

    // URL para atualizar o backend
    const updateUrl = `${API_URL}/fechamento-mensal/historico-condominios/update/${condominioId}/${ano}/${mes}`;
    try {
      const response = await fetch(updateUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`Erro ao atualizar. Status: ${response.status}`);
      }

      // Atualiza localmente o estado após sucesso
      setDadosFechamento((prevDados) =>
        prevDados.map((item) =>
          item.id === condominioId
            ? {
                ...item,
                historico: {
                  ...item.historico,
                  [columnName]: isChecked,
                  [`${columnName.replace("_enviado", "_data_envio")}`]:
                    isChecked ? new Date().toISOString() : null,
                },
              }
            : item,
        ),
      );

      setDadosOrdenados((prevDados) =>
        prevDados.map((item) =>
          item.id === condominioId
            ? {
                ...item,
                historico: {
                  ...item.historico,
                  [columnName]: isChecked,
                  [`${columnName.replace("_enviado", "_data_envio")}`]:
                    isChecked ? new Date().toISOString() : null,
                },
              }
            : item,
        ),
      );
    } catch (error) {
      console.error("Erro na atualização:", error);
    }
  };

  // Renderiza a célula (checkbox + data de envio)
  const renderCheckCell = (
    condominioId: number,
    fieldName: string,
    checked: boolean,
    dataEnvio: string,
  ) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();
      handleCheckboxChange(condominioId, fieldName, e.target.checked);
    };

    return (
      <div style={{ textAlign: "center" }}>
        <Form.Check
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          style={{ cursor: "pointer" }}
        />
        {checked && dataEnvio && (
          <div
            className="data-envio"
            style={{ fontSize: "0.8rem", marginTop: "2px", color: "#666" }}
          >
            {formatDate(dataEnvio)}
          </div>
        )}
      </div>
    );
  };

  // ---------------------------------------------------------------------------------
  // FORMATAÇÃO DE DATAS
  // ---------------------------------------------------------------------------------
  const formatDate = (dateString: string): string => {
    if (!dateString) return "";
    return moment(dateString).format("DD/MM/YYYY");
  };

  // ---------------------------------------------------------------------------------
  // ORDENAÇÃO / FILTRO
  // ---------------------------------------------------------------------------------
  useEffect(() => {
    const sorted = ordenarDados(dadosFechamento);
    setDadosOrdenados(sorted);
  }, [dadosFechamento, filtroAtual]);

  const ordenarDados = (array: ICondominioDados[]): ICondominioDados[] => {
    const { coluna, direcao } = filtroAtual;
    if (!coluna) return array;

    // Se a coluna for "condominio", ordenamos por "fantasia"
    // Senão, ordenamos por booleano
    return [...array].sort((a, b) => {
      let valA: string | boolean = "";
      let valB: string | boolean = "";

      if (coluna === "condominio") {
        valA = a.fantasia;
        valB = b.fantasia;
        if (direcao === "A-Z")
          return (valA as string).localeCompare(valB as string);
        if (direcao === "Z-A")
          return (valB as string).localeCompare(valA as string);
      } else {
        // Pega o campo boolean do histórico para comparação
        const map = fieldMapping[coluna] || "";
        valA = a.historico[map as keyof IHistorico] as boolean;
        valB = b.historico[map as keyof IHistorico] as boolean;

        // direcao "true-false" => exibir "true" primeiro
        if (direcao === "true-false") {
          return Number(valB) - Number(valA);
        }
        if (direcao === "false-true") {
          return Number(valA) - Number(valB);
        }
      }
      return 0;
    });
  };

  const atualizarFiltro = (coluna: string) => {
    setFiltroAtual((estadoAtual) => {
      const { coluna: col, direcao: dir } = estadoAtual;

      if (col === coluna && dir === "false-true") {
        return { coluna: "", direcao: "" };
      } else if (col !== coluna || dir === "") {
        return {
          coluna,
          direcao: coluna === "condominio" ? "A-Z" : "true-false",
        };
      } else if (dir === "A-Z" || dir === "true-false") {
        return { coluna, direcao: dir === "A-Z" ? "Z-A" : "false-true" };
      }
      return { coluna: "", direcao: "" };
    });
  };

  // ---------------------------------------------------------------------------------
  // POP-OVER DE MÊS/ANO
  // ---------------------------------------------------------------------------------
  const mesesNomes = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formatPeriodoExtenso = () => `${mesesNomes[mes - 1]} ${ano}`;

  moment.locale("pt-br");
  const popover = (
    <Popover id="popover-calendar">
      <Popover.Body>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "220px", gap: "10px" }}
        >
          <select
            value={mes}
            onChange={(e) => setMes(Number(e.target.value))}
            className="form-select"
            style={{ width: "120px", textAlign: "center" }}
          >
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {mesesNomes[i]}
              </option>
            ))}
          </select>
          <select
            value={ano}
            onChange={(e) => setAno(Number(e.target.value))}
            className="form-select"
            style={{ width: "100px", textAlign: "center" }}
          >
            {Array.from(
              { length: 10 },
              (_, i) => i + new Date().getFullYear() - 5,
            ).map((a) => (
              <option key={a} value={a}>
                {a}
              </option>
            ))}
          </select>
        </div>
      </Popover.Body>
    </Popover>
  );

  // Handlers dos botões de navegação de mês/ano
  const handlePreviousYear = () => setAno((prev) => prev - 1);
  const handleNextYear = () => setAno((prev) => prev + 1);
  const handlePreviousMonth = () => {
    setMes((prev) => {
      if (prev === 1) {
        setAno((a) => a - 1);
        return 12;
      }
      return prev - 1;
    });
  };
  const handleNextMonth = () => {
    setMes((prev) => {
      if (prev === 12) {
        setAno((a) => a + 1);
        return 1;
      }
      return prev + 1;
    });
  };

  // Filtro global de busca
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  // ---------------------------------------------------------------------------------
  // RENDER
  // ---------------------------------------------------------------------------------

  //SSI 0103 - GCOLLE - Correção Fechamento Mensal permissão - 27/01/2025
  const renderSelectUsuario = () => {
    if (podeSelecionarOutros) {
      return (
        <ReactSelect
          options={usuarios}
          value={selectedUsuario}
          onChange={(valor) => setSelectedUsuario(valor)}
          // ...
        />
      );
    } else {
      return (
        <Form.Control
          readOnly
          value={user?.nome || "Usuário não identificado"}
          style={{ cursor: "not-allowed" }}
        />
      );
    }
  };

  return (
    <div>
      <ToastContainer />

      <AdvanceTableProvider {...table}>
        {/* Cabeçalho de Filtros */}
        <Row className="g-4 mb-4">
          {/* COLUNA: Pesquisar Condomínio */}
          <Col xs={12} sm={3} md={3}>
            <Form.Group controlId="buscar">
              <Form.Label>Pesquisar Condomínio</Form.Label>
              <SearchBox
                placeholder="Pesquisar Condomínio"
                onChange={handleSearchInputChange}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>

          {/* COLUNA: Selecionar Usuário e Selecionar Todos */}
          <Col xs={12} sm={3} md={3}>
            <Form.Group controlId="filtroUsuario">
              <Form.Label>Selecionar Usuário</Form.Label>
              {renderSelectUsuario()}
            </Form.Group>

            {isGestorOuAdmin && (
              <Form.Group controlId="selecionarTodos" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="Selecionar todos usuários"
                  checked={selecionarTodos}
                  onChange={(e) => {
                    setSelecionarTodos(e.target.checked);
                    if (e.target.checked) {
                      setSelectedUsuario({ value: "todos", label: "Todos" });
                    } else {
                      // Defina um comportamento desejado quando desmarcar
                      // Por exemplo, resetar para "todos" ou limpar a seleção
                      setSelectedUsuario(null);
                    }
                  }}
                />
              </Form.Group>
            )}
          </Col>

          {/* BOTÃO BUSCAR - Opcionalmente, pode ser removido se a busca for sempre automática */}
          <Col xs={12} sm={2} md={2}>
            <Form.Group className="mt-4">
              <Button
                variant="primary"
                onClick={handleBuscarDados}
                disabled={carregando}
                className="btn-fixed-width d-flex align-items-center justify-content-center"
              >
                {carregando ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                      style={{ width: "12px", height: "12px" }}
                    />
                    Carregando...
                  </>
                ) : (
                  "Buscar"
                )}
              </Button>
            </Form.Group>
          </Col>

          {/* COLUNA: Selecionar Período */}
          <Col xs={12} sm={3} md={3} className="ms-auto">
            <Form.Group controlId="periodoExtenso">
              <Form.Label>Selecionar Período</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChevronsLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  onClick={() => setAno((prevAno) => prevAno - 1)}
                />
                <ChevronLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "8px" }}
                  onClick={() =>
                    setMes((prevMes) => (prevMes === 1 ? 12 : prevMes - 1))
                  }
                />
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{
                      position: "relative",
                      flexGrow: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Form.Control
                      readOnly
                      placeholder="Selecionar período"
                      style={{
                        border: "1px solid #d3d3d3",
                        borderRadius: "6px",
                        paddingLeft: "40px",
                      }}
                      value={formatPeriodoExtenso()}
                    />
                    <Calendar
                      size={16}
                      style={{
                        color: "gray",
                        position: "absolute",
                        left: "14px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </OverlayTrigger>
                <ChevronRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={() =>
                    setMes((prevMes) => (prevMes === 12 ? 1 : prevMes + 1))
                  }
                />
                <ChevronsRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                  onClick={() => setAno((prevAno) => prevAno + 1)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {/* TABELA */}
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <AdvanceTable
            tableProps={{
              className:
                "phoenix-table fs-9 table-hover text-center align-left",
            }}
          />
          {dadosOrdenados.length === 0 && !carregando && (
            <div className="text-center mt-3">Nenhum dado a exibir.</div>
          )}
          <AdvanceTableFooter pagination />
        </div>
      </AdvanceTableProvider>
    </div>
  );
};

export default UsuarioTable;
