//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Form,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import {
  ChevronLeft,
  ChevronsLeft,
  ChevronRight,
  ChevronsRight,
  Calendar,
  X,
  Check,
} from "feather-icons-react";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import SearchBox from "components/common/SearchBox";
import axios from "axios";
import moment from "moment";
import "moment/locale/pt-br"; // Importa o idioma português
import UserContext from "components/UserContext";

const CondominioTable: React.FC = () => {
  const { user } = useContext(UserContext);
  const userId = user?.userId;
  const podeVisualizarOutros = [1, 27].includes(userId);

  const [mes, setMes] = useState(new Date().getMonth() + 1);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [dados, setDados] = useState<any[]>([]);
  const [documentoSelecionado, setDocumentoSelecionado] =
    useState("balancetes");
  const [carregando, setCarregando] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  /**
   * Função que conta quantos "✓" e quantos registros têm "✓" ou "✕" naquela coluna.
   * @param key Nome do campo (por exemplo, "mes_09_2023")
   * @returns { checkCount, totalCount }
   */
  const getColumnStats = (key: string) => {
    let checkCount = 0;
    let totalCount = 0;

    dados.forEach((item) => {
      const valor = item[key];
      if (valor === "✓") {
        checkCount++;
        totalCount++;
      } else if (valor === "✕") {
        totalCount++;
      }
    });

    return { checkCount, totalCount };
  };

  /**
   * Gera dinamicamente as colunas dos últimos 12 meses,
   * adicionando o N/Total no header.
   */
  const generateColumns = () => {
    const fixedColumns = [
      { accessorKey: "nomeCondominio", header: "Condomínio" },
      { accessorKey: "responsavel", header: "Responsável" },
    ];

    const dynamicColumns = Array.from({ length: 12 }, (_, i) => {
      const currentDate = moment(`${ano}-${mes}`, "YYYY-MM").subtract(
        11 - i,
        "months",
      );
      const colKey = `mes_${currentDate.format("MM_YYYY")}`;

      return {
        accessorKey: colKey,
        header: () => {
          const { checkCount, totalCount } = getColumnStats(colKey);

          return (
            <div style={{ textAlign: "center" }}>
              <div>{currentDate.format("MMM YYYY")}</div>
              <div style={{ fontSize: "12px", color: "gray" }}>
                {checkCount}/{totalCount}
              </div>
            </div>
          );
        },
        cell: ({ getValue }: { getValue: () => any }) => {
          const value = getValue();
          if (value === "✓") {
            return <Check color="green" size={16} />;
          }
          if (value === "✕") {
            return <X color="red" size={16} />;
          }
          return null;
        },
      };
    });

    return [...fixedColumns, ...dynamicColumns];
  };

  const table = useAdvanceTable({
    data: dados,
    columns: generateColumns(),
    pageSize: 15,
    pagination: true,
    sortable: true,
  });

  /**
   * Busca os dados da API de acordo com o documento selecionado e o período (12 meses).
   */
  const fetchDados = async () => {
    setCarregando(true);

    // Define range: 12 meses anteriores + mês atual
    const inicio = moment(`${ano}-${mes}`, "YYYY-MM")
      .subtract(11, "months")
      .startOf("month")
      .format("YYYY-MM-DD");
    const fim = moment(`${ano}-${mes}`, "YYYY-MM")
      .endOf("month")
      .format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${API_URL}/fechamento-mensal/buscar-historico-documentos`,
        {
          params: {
            documento: documentoSelecionado,
            inicio,
            fim,
            //SSI 0103 - GCOLLE - Correção Fechamento Mensal permissão - 27/01/2025
            // Se NÃO pode visualizar outros, manda userId p/ backend filtrar
            ...(!podeVisualizarOutros && { userId }),
          },
        },
      );

      const dataRecebida = response.data.resultados || [];

      // Transforma o retorno da API no formato esperado para exibir na tabela
      const dataProcessada = dataRecebida.map((item: any) => {
        const meses = Array.from({ length: 12 }, (_, i) => {
          const currentDate = moment(`${ano}-${mes}`, "YYYY-MM").subtract(
            11 - i,
            "months",
          );
          const mesAno = currentDate.format("MM/YYYY");

          const docEncontrado = item.documentos.find(
            (doc: any) =>
              doc.mes === currentDate.month() + 1 &&
              doc.ano === currentDate.year(),
          );

          let exibicao;
          if (!docEncontrado) {
            exibicao = "✕";
          } else if (docEncontrado.doc_enviado === 1) {
            exibicao = "✓";
          } else {
            exibicao = "✕";
          }

          return {
            [`mes_${mesAno.replace("/", "_")}`]: exibicao,
          };
        });

        return {
          nomeCondominio: item.fantasia || "Desconhecido",
          responsavel: item.responsavel || "Não informado",
          ...Object.assign({}, ...meses),
        };
      });

      setDados(dataProcessada);
    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      setCarregando(false);
    }
  };

  useEffect(() => {
    fetchDados();
  }, [ano, mes, documentoSelecionado]);

  const formatPeriodoExtenso = () => {
    const mesesNomes = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    return `${mesesNomes[mes - 1]} ${ano}`;
  };

  const popover = (
    <Popover id="popover-calendar">
      <Popover.Body>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "220px", gap: "10px" }}
        >
          <select
            value={mes}
            onChange={(e) => setMes(Number(e.target.value))}
            className="form-select"
            style={{ width: "120px", textAlign: "center" }}
          >
            {[...Array(12)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {moment().month(i).format("MMMM").charAt(0).toUpperCase() +
                  moment().month(i).format("MMMM").slice(1)}
              </option>
            ))}
          </select>
          <select
            value={ano}
            onChange={(e) => setAno(Number(e.target.value))}
            className="form-select"
            style={{ width: "100px", textAlign: "center" }}
          >
            {Array.from(
              { length: 10 },
              (_, i) => i + new Date().getFullYear() - 5,
            ).map((y) => (
              <option key={y} value={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </Popover.Body>
    </Popover>
  );

  // Filtro Global (texto) na tabela
  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        {/* FILTROS E CABEÇALHO */}
        <Row className="g-4 align-items-center mb-4">
          <Col xs={12} sm={3} md={3}>
            <Form.Group controlId="buscar">
              <Form.Label>Buscar Condomínio</Form.Label>
              <SearchBox
                placeholder="Pesquisar Condomínio"
                onChange={handleSearchInputChange}
                style={{ width: "100%" }}
              />
            </Form.Group>
          </Col>

          <Col xs={12} sm={2} md={2}>
            <Form.Group controlId="filtroStatus">
              <Form.Label>Selecionar Documento</Form.Label>
              <Form.Select
                value={documentoSelecionado}
                onChange={(e) => setDocumentoSelecionado(e.target.value)}
                className="form-select-sm text-center"
                style={{
                  fontWeight: "bold",
                  fontSize: "13px",
                }}
              >
                <option value="balancetes">Balancetes</option>
                <option value="boleto">Boleto</option>
                <option value="consumosIndividuais">Consumos Ind.</option>
                <option value="leitura">Leitura</option>
                <option value="reinf">Re Inf</option>
                <option value="reservas">Reservas</option>
              </Form.Select>
            </Form.Group>
          </Col>

          {/* Seleção de Mês/Ano */}
          <Col xs={12} sm={3} md={3} className="ms-auto">
            <Form.Group controlId="periodoExtenso">
              <Form.Label>Selecionar Período</Form.Label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ChevronsLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "4px" }}
                  onClick={() => setAno((prevAno) => prevAno - 1)}
                />
                <ChevronLeft
                  size={16}
                  style={{ cursor: "pointer", marginRight: "8px" }}
                  onClick={() =>
                    setMes((prevMes) => (prevMes === 1 ? 12 : prevMes - 1))
                  }
                />
                <OverlayTrigger
                  trigger="click"
                  placement="bottom"
                  overlay={popover}
                  rootClose
                >
                  <div
                    style={{
                      position: "relative",
                      flexGrow: 1,
                      cursor: "pointer",
                    }}
                  >
                    <Form.Control
                      readOnly
                      placeholder="Selecionar período"
                      style={{
                        border: "1px solid #d3d3d3",
                        borderRadius: "6px",
                        paddingLeft: "40px",
                      }}
                      value={formatPeriodoExtenso()}
                    />
                    <Calendar
                      size={16}
                      style={{
                        color: "gray",
                        position: "absolute",
                        left: "14px",
                        top: "50%",
                        transform: "translateY(-50%)",
                      }}
                    />
                  </div>
                </OverlayTrigger>
                <ChevronRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "8px" }}
                  onClick={() =>
                    setMes((prevMes) => (prevMes === 12 ? 1 : prevMes + 1))
                  }
                />
                <ChevronsRight
                  size={16}
                  style={{ cursor: "pointer", marginLeft: "4px" }}
                  onClick={() => setAno((prevAno) => prevAno + 1)}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>

        {/* TABELA */}
        {carregando ? (
          <div className="text-center my-4">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </div>
        ) : (
          <>
            <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
              <AdvanceTable
                tableProps={{
                  className:
                    "phoenix-table fs-9 table-hover text-center align-left",
                }}
              />
            </div>

            <AdvanceTableFooter pagination />

            {dados.length === 0 && !carregando && (
              <div className="text-center mt-3">
                Nenhum dado encontrado para o período selecionado.
              </div>
            )}
          </>
        )}
      </AdvanceTableProvider>
    </div>
  );
};

export default CondominioTable;
