//SSI 0081 - GCOLLE - Fechamento Mensal (Por cond, Por usuario, Visao Geral) - 07/01/2025
import React, { useState, useEffect, useContext } from "react";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import VisaoGeralTable from "./table/VisaoGeralTable";
import CondominioTable from "./table/CondominioTable";
import UsuarioTable from "./table/UsuarioTable";

// IMPORT DO CONTEXTO (ou do hook que busca do localStorage)
import UserContext from "components/UserContext";

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Fechamento Mensal", active: true },
];

const FechamentoMensal: React.FC = () => {
  //SSI 0103 - GCOLLE - Correção Fechamento Mensal permissão - 27/01/2025
  const { user } = useContext(UserContext);
  const userId = user?.userId;

  const [view, setView] = useState<string>("porUsuario");

  // Toda vez que userId mudar, se ele NÃO for 1 ou 27, forçamos a view "porUsuario"
  useEffect(() => {
    if (userId !== 1 && userId !== 27) {
      setView("porUsuario");
    }
  }, [userId]);

  const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newView = event.target.value;

    // Se o userId não for 1 ou 27, não deixamos mudar pra visaoGeral
    if (userId !== 1 && userId !== 27 && newView === "visaoGeral") {
      return; // ou setView("porUsuario");
    }

    setView(newView);
  };

  return (
    <div>
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <div className="d-flex align-items-center justify-content-between mb-4">
        <h2 className="mb-0">Fechamento Mensal</h2>
        <select
          className="form-select"
          style={{
            width: "200px",
            fontSize: "1.15rem",
            padding: "0.5rem 1rem",
          }}
          value={view}
          onChange={handleViewChange}
        >
          {/* Só exibe "Visão Geral" se userId for 1 ou 27 */}
          {[1, 27].includes(userId) && (
            <option value="visaoGeral">Visão Geral</option>
          )}

          <option value="porCondominio">Por Condomínio</option>
          <option value="porUsuario">Por Usuário</option>
        </select>
      </div>

      {/* Renderiza a tabela baseada na seleção */}
      <div>
        {/* Só renderiza <VisaoGeralTable> se userId for 1 ou 27 */}
        {view === "visaoGeral" && [1, 27].includes(userId) && (
          <VisaoGeralTable />
        )}

        {view === "porCondominio" && <CondominioTable />}
        {view === "porUsuario" && <UsuarioTable />}
      </div>
    </div>
  );
};

export default FechamentoMensal;
