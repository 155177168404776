// SSI 0107 - GCOLLE - Adicionar Cadastro Squad - 03/02/2024
// SSI 0111 - GCOLLE - Adicionando editar carteira para ter CADASTRO, e modificando lógica squad para apenas verificar o RH e Financeiro - 06/02/2025
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Eye, Check, X as XIcon } from "feather-icons-react";
import Button from "components/base/Button";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import SearchBox from "components/common/SearchBox";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import { Col, Row, Modal, Table, Form } from "react-bootstrap";
import AdvanceTable from "components/base/AdvanceTable";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import ReactSelect from "components/base/ReactSelect";

interface ILider {
  id: number | null;
  nome: string | null;
}

interface IMembro {
  id: number;
  nome: string;
}

interface IMembrosPorArea {
  FINANCEIRO: IMembro[];
  COBRANCA: IMembro[];
  RH: IMembro[];
  ASSEMBLEIAS: IMembro[];
  CADASTRO: IMembro[];
  JURIDICO: IMembro[];
}

interface ISquad {
  id: any;
  squad_id: number;
  nome_squad: string;
  lider: ILider;
  membros: IMembrosPorArea;
}

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Squads", active: true },
];

const Geral = () => {
  const [squads, setSquads] = useState<ISquad[]>([]);
  const [selectedSquad, setSelectedSquad] = useState<ISquad | null>(null);
  const [showDetails, setShowDetails] = useState<boolean>(false);

  // Controle do modal de criação de Squad
  const [show, setShow] = useState<boolean>(false);
  const [validated, setValidated] = useState<boolean>(false);
  const [formData, setFormData] = useState({ nome_squad: "", lider_squad: "" });
  const [isLoading, setIsLoading] = useState(false);

  // --- Estados para edição de membros dentro do Modal de Detalhes ---
  // Indica se estamos no modo de edição
  const [isEditing, setIsEditing] = useState<boolean>(false);

  // Armazena o ID do usuário selecionado para cada área
  const [selectedUsersByArea, setSelectedUsersByArea] = useState<{
    [area: string]: number | null;
  }>({});

  // Exibe ou oculta o ReactSelect de cada área
  const [showSelectByArea, setShowSelectByArea] = useState<{
    [area: string]: boolean;
  }>({});

  // Lista de opções de usuários disponíveis para seleção
  const [userOptions, setUserOptions] = useState<
    { value: number; label: string }[]
  >([]);

  const [userOptionsByArea, setUserOptionsByArea] = useState<{
    [area: string]: { value: number; label: string }[];
  }>({});

  const [selectedLider, setSelectedLider] = useState<{
    value: number;
    label: string;
  } | null>(null);

  const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar abertura do menu
  const [menuOpenByArea, setMenuOpenByArea] = useState<Record<string, boolean>>(
    {},
  );
  const [menuOpenLider, setMenuOpenLider] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const styles = `
    .action-buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .action-buttons .icon-button {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.2s;
    }
    .phoenix-table tbody tr:hover .icon-button {
      opacity: 1 !important;
      visibility: visible;
    }
  `;

  useEffect(() => {
    fetchSquads();
  }, []);

  // --- 1) Buscar lista de squads ---
  const fetchSquads = async () => {
    try {
      const response = await axios.get<ISquad[]>(`${API_URL}/squad/buscar`);
      setSquads(response.data);
    } catch (error) {
      console.error("Erro ao buscar squads:", error);
      toast.error("Erro ao buscar squads");
    }
  };

  // --- 2) Buscar lista de usuários para o ReactSelect ---
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/search-users-by-name`);
      const options = response.data.map((u: any) => ({
        value: u.id_func, // ID do usuário
        label: u.nome,
      }));
      setUserOptions(options);
    } catch (error) {
      console.error("Erro ao carregar todos os usuários:", error);
      toast.error("Erro ao buscar usuários");
    }
  };

  const fetchUsersByArea = async (area: string) => {
    try {
      const response = await axios.get(`${API_URL}/user/search-users-by-area`, {
        params: { area },
      });
      return response.data.map((u: any) => ({
        value: u.id_func,
        label: u.nome,
      }));
    } catch (error) {
      console.error("Erro ao buscar usuários por área:", error);
      toast.error("Erro ao buscar usuários por área.");
      return [];
    }
  };

  const handleShowSelect = async (area: string) => {
    setShowSelectByArea((prev) => ({ ...prev, [area]: !prev[area] }));

    if (!userOptionsByArea[area]) {
      const options = await fetchUsersByArea(area);
      setUserOptionsByArea((prev) => ({ ...prev, [area]: options }));
    }
  };

  // Quando abrir o modal de criar Squad, carrega a lista de usuários
  useEffect(() => {
    if (show) {
      fetchAllUsers();
    }
  }, [show]);

  // --- 3) Controla o input do líder no modal de criação ---
  const handleUserSelectChange = (
    option: { value: number; label: string } | null,
  ) => {
    setFormData((prev) => ({
      ...prev,
      lider_squad: option ? String(option.value) : "",
    }));
  };

  // --- 4) Abrir/Fechar modal de detalhes ---
  const handleOpenDetails = (squad: ISquad) => {
    setSelectedSquad(squad);
    setShowDetails(true);
    setIsEditing(false); // Ao abrir detalhes, inicia sem edição
  };
  const handleCloseDetails = () => {
    setShowDetails(false);
    setSelectedSquad(null);
  };

  // --- 5) Abrir/Fechar modal de criação ---
  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setValidated(false);
    setFormData({ nome_squad: "", lider_squad: "" });
  };

  // --- 6) Lidar com inputs do modal de criação ---
  const handleInputChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  > = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // --- 7) Criar Squad ---
  const handleSaveSquad = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      toast.error("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    try {
      setIsLoading(true);
      const { nome_squad, lider_squad } = formData;
      const requestData = {
        nome_squad,
        lider_squad: lider_squad.trim() ? lider_squad : null,
      };

      const response = await axios.post(`${API_URL}/squad/criar`, requestData);

      if (response.status === 201) {
        toast.success("Squad criado com sucesso!");
        await fetchSquads(); // Atualiza a lista de squads
        handleClose();
      } else {
        toast.error("Erro ao criar squad.");
      }
    } catch (error: any) {
      console.error("Erro ao criar squad:", error);
      toast.error(error.response?.data?.erro || "Erro ao criar squad.");
    } finally {
      setIsLoading(false);
    }
  };

  // --- 8) Configura a tabela de Squads ---
  const table = useAdvanceTable<ISquad>({
    data: squads,
    columns: [
      { accessorKey: "squad_id", header: "ID" },
      { accessorKey: "nome_squad", header: "Nome do Squad" },
      {
        accessorKey: "lider.nome",
        header: "Líder",
        cell: ({ row }: any) => row.original.lider?.nome || "—",
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => (
          <div className="action-buttons">
            <Button
              variant="link"
              className="btn-sm text-primary p-0 m-0 icon-button"
              onClick={() => handleOpenDetails(row.original)}
              title="Visualizar Squad"
            >
              <Eye size={16} />
            </Button>
          </div>
        ),
      },
    ],
    pageSize: 20,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "squad_id", desc: false }],
    },
  });

  // --- 9) Modo de edição no modal de detalhes ---
  const handleEditDetails = () => {
    setIsEditing(true);
    // Podemos carregar a lista de usuários aqui se quisermos
    // mas se for a mesma do modal, talvez já esteja carregada
    fetchAllUsers();
  };
  const handleConfirmEdit = () => {
    setIsEditing(false);
    toast.success("Edição confirmada!");
    // Caso precise de alguma ação adicional, coloque aqui
  };

  // --- 11) Atualizar selectedUsersByArea ao escolher um usuário ---
  const handleChangeUserSelectArea = (
    area: string,
    option: { value: number; label: string } | null,
  ) => {
    setSelectedUsersByArea((prev) => ({
      ...prev,
      [area]: option ? option.value : null,
    }));
  };

  // --- 12) Adicionar membro ao Squad ---
  const handleAddMember = async (area: string) => {
    if (!selectedSquad) return;

    try {
      const usuario_id = selectedUsersByArea[area];
      if (!usuario_id) {
        toast.error("Selecione um usuário para adicionar.");
        return;
      }

      const response = await axios.post(
        `${API_URL}/squad/adicionar-membro/${selectedSquad.squad_id}`,
        {
          usuario_id,
          area,
        },
      );

      if (response.status === 201 || response.status === 200) {
        toast.success(
          response.data.message || "Membro adicionado com sucesso!",
        );
        await refetchSquadData();
        // Resetar o estado local
        setSelectedUsersByArea((prev) => ({ ...prev, [area]: null }));
        setShowSelectByArea((prev) => ({ ...prev, [area]: false }));
      }
    } catch (error: any) {
      console.error("Erro ao adicionar membro:", error);
      toast.error(error.response?.data?.erro || "Erro ao adicionar membro.");
    }
  };

  // --- 13) Recarregar dados do Squad específico ---
  const refetchSquadData = async () => {
    if (!selectedSquad) return;
    try {
      const response = await axios.get(
        `${API_URL}/squad/detalhes/${selectedSquad.squad_id}`,
      );
      setSelectedSquad(response.data);
    } catch (error: any) {
      console.error("Erro ao buscar detalhes do squad:", error.response);
      toast.error("Erro ao carregar detalhes do squad.");
    }
  };

  const handleRemoveMember = async (area: string, membroId: number) => {
    if (!selectedSquad) return;

    try {
      const response = await axios.delete(
        `${API_URL}/squad/excluir-membro/${selectedSquad.squad_id}`,
        {
          headers: { "Content-Type": "application/json" },
          data: { usuario_id: membroId, area },
        },
      );

      // console.log(response);

      if (response.status === 200 || response.status === 204) {
        toast.success("Membro removido com sucesso!");
        await refetchSquadData(); // Atualiza os membros do squad
      }
    } catch (error) {
      console.error("Erro ao remover membro:", error);
      toast.error("Erro ao remover membro.");
    }
  };

  const handleLiderChange = async (
    selectedLider: { value: number; label: string } | null,
  ) => {
    if (!selectedSquad || selectedLider === undefined) return;

    try {
      const response = await fetch(
        `${API_URL}/squad/editar/${selectedSquad.squad_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            lider_squad: selectedLider ? selectedLider.value : null,
          }),
        },
      );

      if (!response.ok) {
        throw new Error("Erro ao atualizar o líder do squad");
      }

      toast.success("Líder do squad atualizado com sucesso!");
      await refetchSquadData(); // Atualiza os dados do squad
    } catch (error) {
      console.error("Erro ao atualizar líder do squad:", error);
      toast.error("Erro ao atualizar o líder do squad.");
    }
  };

  return (
    <div>
      <style>{styles}</style>
      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-4">Cadastro de Squads</h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-4 align-items-end">
              <Col xs={12} sm={4} md={4}>
                <SearchBox
                  placeholder="Pesquisar Squads"
                  onChange={(e) =>
                    table.setGlobalFilter(e.target.value || undefined)
                  }
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              />
              <Col xs="auto">
                <Button variant="primary" onClick={handleShow}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Adicionar Squad
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: "phoenix-table fs-9 table-hover" }}
            />
          </div>
          <AdvanceTableFooter pagination />
        </AdvanceTableProvider>

        {/* MODAL DE DETALHES DO SQUAD */}
        <Modal show={showDetails} onHide={handleCloseDetails} size="xl">
          <Modal.Header closeButton>
            <Modal.Title as="h5" className="w-100 text-center">
              Detalhes do Squad - <strong>{selectedSquad?.nome_squad}</strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {selectedSquad && (
              <>
                {/* Seletor de Líder do Squad */}
                <div className="d-flex align-items-center justify-content-center mb-4">
                  <span className="fw-bold me-2">Líder:</span>
                  <div
                    style={{ minWidth: "250px" }}
                    className="d-flex align-items-center"
                  >
                    <ReactSelect
                      isDisabled={!isEditing}
                      options={userOptions}
                      value={
                        selectedLider ||
                        (selectedSquad?.lider?.id
                          ? {
                              value: selectedSquad.lider.id,
                              label: selectedSquad.lider.nome,
                            }
                          : null)
                      }
                      onChange={(newValue) => {
                        setSelectedLider(
                          newValue as { value: number; label: string } | null,
                        );
                        setMenuOpen(false);
                      }}
                      onMenuOpen={() => setMenuOpen(true)}
                      onMenuClose={() => setMenuOpen(false)}
                      menuIsOpen={menuOpen}
                      placeholder="Selecione o líder"
                      isClearable
                    />
                    {isEditing && (
                      <Button
                        variant="link"
                        className="p-0 ms-2 text-success"
                        title="Adicionar líder"
                        onClick={() => handleLiderChange(selectedLider)}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    )}
                  </div>
                </div>

                {/* Lista de Membros por Área */}
                <div className="row g-4">
                  {Object.entries(selectedSquad.membros).map(
                    ([area, membros]) => {
                      // SSI 0111 - GCOLLE - Substituir "JURIDICO" por "Consultoria/Notificações"
                      const areaNomeCorrigido =
                        area === "JURIDICO" ? "Consultoria/Notificações" : area;

                      return (
                        <div className="col-lg-4 col-md-6 d-flex" key={area}>
                          <div className="card flex-grow-1 shadow-sm p-3 d-flex flex-column position-relative">
                            {/* Cabeçalho da Área */}
                            <div className="d-flex justify-content-between align-items-center mb-2">
                              <h5 className="text-center text-primary mb-0 flex-grow-1">
                                {areaNomeCorrigido}
                              </h5>

                              {isEditing && (
                                <Button
                                  variant="link"
                                  className="p-0 ms-2 text-success"
                                  title={`Adicionar membro em ${areaNomeCorrigido}`}
                                  onClick={() => handleShowSelect(area)}
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </Button>
                              )}
                            </div>

                            {/* Lista de Membros com botão de remoção */}
                            <ul className="list-group list-group-flush flex-grow-1">
                              {/* ReactSelect - aparece se showSelectByArea[area] === true */}
                              {isEditing && showSelectByArea[area] && (
                                <div
                                  className="d-flex align-items-center mt-2"
                                  style={{ gap: "0.5rem" }}
                                >
                                  <div style={{ flex: 1 }}>
                                    <ReactSelect
                                      placeholder="Selecione o usuário"
                                      options={userOptionsByArea[area] ?? []}
                                      value={
                                        selectedUsersByArea[area]
                                          ? userOptions.find(
                                              (opt) =>
                                                opt.value ===
                                                selectedUsersByArea[area],
                                            ) || null
                                          : null
                                      }
                                      onChange={(opt) => {
                                        handleChangeUserSelectArea(
                                          area,
                                          opt as {
                                            value: number;
                                            label: string;
                                          } | null,
                                        );
                                        setMenuOpenByArea((prev) => ({
                                          ...prev,
                                          [area]: false,
                                        })); // Fecha o menu após seleção
                                      }}
                                      onMenuOpen={() =>
                                        setMenuOpenByArea((prev) => ({
                                          ...prev,
                                          [area]: true,
                                        }))
                                      }
                                      onMenuClose={() =>
                                        setMenuOpenByArea((prev) => ({
                                          ...prev,
                                          [area]: false,
                                        }))
                                      }
                                      menuIsOpen={menuOpenByArea[area] || false}
                                      isClearable
                                    />
                                  </div>
                                  <Button
                                    variant="link"
                                    className="text-success"
                                    title="Confirmar adição"
                                    onClick={() => handleAddMember(area)}
                                    style={{ fontSize: "1.2rem" }}
                                  >
                                    <Check size={18} />
                                  </Button>
                                </div>
                              )}

                              {membros.length > 0 ? (
                                membros.map((membro: any) => (
                                  <li
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                    key={membro.id}
                                  >
                                    {membro.nome}
                                    {isEditing && (
                                      <Button
                                        variant="link"
                                        className="p-0 text-danger"
                                        title={`Remover ${membro.nome}`}
                                        onClick={() =>
                                          handleRemoveMember(area, membro.id)
                                        }
                                        style={{ fontSize: "1.2rem" }}
                                      >
                                        <XIcon size={18} />
                                      </Button>
                                    )}
                                  </li>
                                ))
                              ) : (
                                <li className="list-group-item text-center text-muted">
                                  Nenhum membro
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    },
                  )}
                </div>
              </>
            )}
          </Modal.Body>

          <Modal.Footer>
            {isEditing ? (
              <Button variant="success" onClick={handleConfirmEdit}>
                Confirmar Edição
              </Button>
            ) : (
              <Button variant="primary" onClick={handleEditDetails}>
                Editar
              </Button>
            )}
            <Button variant="secondary" onClick={handleCloseDetails}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL CRIAR SQUAD */}
        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cadastrar Squad</Modal.Title>
          </Modal.Header>

          <Form noValidate validated={validated} onSubmit={handleSaveSquad}>
            <Modal.Body>
              <Row className="gx-3 gy-4">
                <Col sm={6}>
                  <Form.Label>Nome do Squad</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Digite o nome"
                    name="nome_squad"
                    value={formData.nome_squad}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, insira o nome do Squad.
                  </Form.Control.Feedback>
                </Col>

                <Col sm={6}>
                  <Form.Label>Líder do Squad (Opcional)</Form.Label>
                  <ReactSelect
                    placeholder="Selecione o líder"
                    options={userOptions}
                    value={
                      userOptions.find(
                        (opt) => opt.value === Number(formData.lider_squad),
                      ) || null
                    }
                    onChange={(newValue) => {
                      handleUserSelectChange(
                        newValue as { value: number; label: string } | null,
                      );
                      setMenuOpenLider(false); // Fecha o menu após seleção
                    }}
                    onMenuOpen={() => setMenuOpenLider(true)}
                    onMenuClose={() => setMenuOpenLider(false)}
                    menuIsOpen={menuOpenLider}
                    isClearable
                  />
                  <Form.Text className="text-muted">
                    Deixe em branco se o Squad não tiver um líder.
                  </Form.Text>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Fechar
              </Button>
              <Button variant="primary" type="submit" disabled={isLoading}>
                {isLoading ? "Salvando..." : "Criar"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default Geral;
