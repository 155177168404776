/**
 * ***********************************
 * Autor: Matheus
 * Data: 09/02/2024
 * Atividade: Editar/Adicionar ausência
 * ***********************************
 */

// SSI 0085 - GCOLLE - Informações Usuários, Refazendo página para padrão novo - 10/01/2025
// SSI 0107 - GCOLLE - Adicionar Cadastro Squad - 03/02/2024

import React, { useState, useEffect, ChangeEvent, FormEvent, FC } from "react";
import axios from "axios";
import moment from "moment";

import { Col, Row, Modal, Form } from "react-bootstrap";
import PageBreadcrumb from "components/common/PageBreadcrumb";
import Badge from "components/base/Badge";
import Button from "components/base/Button";
import ReactSelect from "components/base/ReactSelect";
import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Edit, Trash2, XCircle } from "feather-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

interface IAusencia {
  id_aus: number;
  nome: string;
  motivo: string;
  dt_inicio: string;
  dt_fim: string;
  status: string;
}

interface IUserOption {
  value: number;
  label: string;
}

interface IFormAusencia {
  id_aus?: number;
  nome?: string;
  id_func?: number;
  motivo: string;
  dt_inicio: string;
  dt_fim: string;
  status?: string;
}

const API_URL = process.env.REACT_APP_API_URL;

const defaultBreadcrumbItems = [
  { label: "Home", link: "/" },
  { label: "Ausências", active: true },
];

const Ausencia: FC = () => {
  const [ausencias, setAusencias] = useState<IAusencia[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [selectedAusencia, setSelectedAusencia] = useState<IAusencia | null>(
    null,
  );

  const [formAusencia, setFormAusencia] = useState<IFormAusencia>({
    motivo: "Atestado",
    dt_inicio: "",
    dt_fim: "",
  });

  const [userOptions, setUserOptions] = useState<IUserOption[]>([]);

  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);

  useEffect(() => {
    fetchAusencias();
  }, []);

  const fetchAusencias = async () => {
    try {
      const response = await axios.get<IAusencia[]>(
        `${API_URL}/editar-ausencia/ausencias`,
      );
      setAusencias(response.data);
    } catch (error) {
      console.error("Erro ao buscar ausências:", error);
      toast.error("Erro ao buscar ausências");
    }
  };

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/user/search-users-by-name`);
      const options = response.data.map((u: any) => ({
        value: u.id_func,
        label: u.nome,
      }));
      setUserOptions(options);
    } catch (error) {
      console.error("Erro ao carregar todos os usuários:", error);
      toast.error("Erro ao buscar usuários");
    }
  };

  const handleShowCreateAusencia = () => {
    setIsEditing(false);
    setSelectedAusencia(null);
    setFormAusencia({
      motivo: "Atestado",
      dt_inicio: moment().format("YYYY-MM-DD"),
      dt_fim: "",
    });
    fetchAllUsers();
    setShowModal(true);
  };

  const handleEditAusenciaClick = (ausencia: IAusencia) => {
    setIsEditing(true);
    setSelectedAusencia(ausencia);

    setFormAusencia({
      id_aus: ausencia.id_aus,
      nome: ausencia.nome,
      motivo: ausencia.motivo,
      dt_inicio: ausencia.dt_inicio.substring(0, 10),
      dt_fim: ausencia.dt_fim.substring(0, 10),
      status: ausencia.status,
    });
    setShowModal(true);
  };

  const handleSaveAusencia = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (new Date(formAusencia.dt_fim) < new Date(formAusencia.dt_inicio)) {
      toast.error("Data final não pode ser anterior à data inicial.");
      return;
    }

    if (isEditing && selectedAusencia) {
      await updateAusencia();
    } else {
      await createAusencia();
    }
  };

  const createAusencia = async () => {
    try {
      const payload = {
        id: formAusencia.id_func,
        nome: formAusencia.nome,
        motivo: formAusencia.motivo,
        dataInicio: formAusencia.dt_inicio,
        dataFim: formAusencia.dt_fim,
      };

      if (
        !payload.id ||
        !payload.nome ||
        !payload.motivo ||
        !payload.dataInicio ||
        !payload.dataFim
      ) {
        toast.error("Todos os campos devem ser preenchidos!");
        return;
      }

      if (new Date(payload.dataFim) < new Date(payload.dataInicio)) {
        toast.error("A data final não pode ser anterior à data inicial.");
        return;
      }

      const response = await axios.post(
        `${API_URL}/ausencia/add-absence`,
        payload,
      );

      if (response.data.success) {
        toast.success("Ausência criada com sucesso!");
        setShowModal(false);
        fetchAusencias();
      } else {
        toast.error(response.data.message || "Erro ao criar ausência!");
      }
    } catch (error) {
      console.error("Erro ao criar ausência:", error);
      toast.error("Erro ao criar ausência!");
    }
  };

  const updateAusencia = async () => {
    if (!selectedAusencia) return;

    try {
      // Prepara o payload para envio
      const payload = {
        dt_inicio: formAusencia.dt_inicio,
        dt_fim: formAusencia.dt_fim,
        motivo: formAusencia.motivo, // Inclui o motivo
      };

      // Validações básicas
      if (!payload.dt_inicio || !payload.dt_fim || !payload.motivo) {
        toast.error("Todos os campos devem ser preenchidos!");
        return;
      }

      if (new Date(payload.dt_fim) < new Date(payload.dt_inicio)) {
        toast.error("A data final não pode ser anterior à data inicial.");
        return;
      }

      // Chamada à API
      const response = await axios.put(
        `${API_URL}/editar-ausencia/ausencias/${selectedAusencia.id_aus}`,
        payload,
      );

      if (response.data.success) {
        toast.success("Ausência atualizada com sucesso!");
        setShowModal(false); // Fecha o modal
        fetchAusencias(); // Atualiza a tabela
      } else {
        toast.error(response.data.message || "Erro ao atualizar ausência!");
      }
    } catch (error) {
      console.error("Erro ao atualizar ausência:", error);
      toast.error("Erro ao atualizar ausência!");
    }
  };

  const [ausenciaToDelete, setAusenciaToDelete] = useState<IAusencia | null>(
    null,
  );
  const handleShowConfirmDelete = (ausencia: IAusencia) => {
    setAusenciaToDelete(ausencia);
    setShowConfirmDelete(true);
  };
  const handleCloseConfirmDelete = () => setShowConfirmDelete(false);

  const handleDeleteAusencia = async () => {
    if (!ausenciaToDelete) return;

    try {
      await axios.put(
        `${API_URL}/editar-ausencia/cancelar/${ausenciaToDelete.id_aus}`,
      );

      toast.success("Ausência excluída com sucesso!");
      setAusencias((prev) =>
        prev.filter((a) => a.id_aus !== ausenciaToDelete.id_aus),
      );
      handleCloseConfirmDelete();
    } catch (error) {
      console.error("Erro ao excluir ausência:", error);
      toast.error("Erro ao excluir ausência");
    }
  };

  function renderStatusBadge(status: string) {
    let variant: "success" | "warning" | "danger";
    let text: string;

    switch (status) {
      case "Ausente":
        variant = "warning";
        text = "Ausente";
        break;
      case "Inativo":
        variant = "danger";
        text = "Inativo";
        break;
      default:
        variant = "success";
        text = "Ativo";
        break;
    }

    return (
      <Badge
        variant="phoenix"
        bg={variant}
        className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
        style={{
          minWidth: "80px",
        }}
      >
        {text}
      </Badge>
    );
  }

  const table = useAdvanceTable<IAusencia>({
    data: ausencias,
    columns: [
      {
        accessorKey: "nome",
        header: "Nome",
      },
      {
        accessorKey: "motivo",
        header: "Motivo",
      },
      {
        accessorKey: "dt_inicio",
        header: "Data de Início",
        cell: ({ row }: any) =>
          moment(row.original.dt_inicio).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "dt_fim",
        header: "Data de Fim",
        cell: ({ row }: any) =>
          moment(row.original.dt_fim).format("DD/MM/YYYY"),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }: any) => renderStatusBadge(row.original.status),
      },
      {
        id: "actions",
        header: "Ações",
        cell: ({ row }: any) => {
          const ausencia: IAusencia = row.original;
          return (
            <div className="action-buttons">
              <Button
                variant="link"
                className="btn-sm text-primary p-0 m-0 icon-button"
                onClick={() => handleEditAusenciaClick(ausencia)}
                title="Editar ausência"
              >
                <Edit size={16} />
              </Button>

              <Button
                variant="link"
                className="btn-sm text-warning p-0 m-0 icon-button"
                onClick={() => handleShowConfirmDelete(ausencia)}
                title="Cancelar ausência"
              >
                <XCircle size={16} />
              </Button>
            </div>
          );
        },
      },
    ],
    pageSize: 15,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "nome", desc: false }],
    },
  });

  const handleFormAusenciaChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value } = e.target;
    setFormAusencia((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUserSelectChange = (option: IUserOption | null) => {
    if (!option) return;
    setFormAusencia((prev) => ({
      ...prev,
      id_func: option.value,
      nome: option.label,
    }));
  };

  return (
    <div>
      <style>
        {`
          .action-buttons {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .action-buttons .icon-button {
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.2s;
          }
          .phoenix-table tbody tr:hover .icon-button {
            opacity: 1 !important;
            visibility: visible;
          }
        `}
      </style>

      <ToastContainer />
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <div className="mb-9">
        <h2 className="mb-4" style={{ textAlign: "left" }}>
          Gerenciamento de Ausências
        </h2>

        <AdvanceTableProvider {...table}>
          <div className="mb-4">
            <Row className="g-4 align-items-end">
              <Col xs={12} sm={4} md={4}>
                <Form.Group controlId="buscarAusencia">
                  <Form.Label>Buscar Ausência</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Pesquisar por nome..."
                    onChange={(e) => table.setGlobalFilter(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                xs="auto"
                className="scrollbar overflow-hidden-y flex-grow-1"
              />
              <Col xs="auto">
                <Button variant="primary" onClick={handleShowCreateAusencia}>
                  <FontAwesomeIcon icon={faPlus} className="me-2" />
                  Adicionar Ausência
                </Button>
              </Col>
            </Row>
          </div>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{ className: "phoenix-table fs-9 table-hover" }}
            />
          </div>
          <AdvanceTableFooter pagination />
        </AdvanceTableProvider>

        {/* Modal de Criar/Editar Ausência */}
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          backdrop="static"
        >
          <Form onSubmit={handleSaveAusencia}>
            <Modal.Header closeButton>
              <Modal.Title>
                {isEditing ? "Editar Ausência" : "Cadastrar Nova Ausência"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!isEditing && (
                <>
                  <Form.Label>Usuário</Form.Label>
                  <ReactSelect
                    placeholder="Selecione o usuário"
                    options={userOptions}
                    onChange={(opt) =>
                      handleUserSelectChange(opt as IUserOption)
                    }
                    isClearable
                  />
                </>
              )}

              <Form.Group className="mt-3">
                <Form.Label>Motivo</Form.Label>
                <Form.Select
                  name="motivo"
                  value={formAusencia.motivo}
                  onChange={handleFormAusenciaChange}
                >
                  <option value="Atestado">Atestado</option>
                  <option value="Assunto Pessoal">Assunto Pessoal</option>
                  <option value="Ferias">Férias</option>
                  <option value="Folga">Folga</option>
                  <option value="Inativação">Inativação</option>
                </Form.Select>
              </Form.Group>

              <Row className="mt-3">
                <Col sm={6}>
                  <Form.Label>Data de Início</Form.Label>
                  <Form.Control
                    type="date"
                    name="dt_inicio"
                    value={formAusencia.dt_inicio}
                    onChange={handleFormAusenciaChange}
                    required
                  />
                </Col>
                <Col sm={6}>
                  <Form.Label>Data de Fim</Form.Label>
                  <Form.Control
                    type="date"
                    name="dt_fim"
                    value={formAusencia.dt_fim}
                    onChange={handleFormAusenciaChange}
                    required
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                {isEditing ? "Salvar Alterações" : "Criar"}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Modal de Confirmação de Cancelamento */}
        <Modal show={showConfirmDelete} onHide={handleCloseConfirmDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmar Cancelamento</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Tem certeza que deseja{" "}
            <strong style={{ color: "#fca903" }}>CANCELAR</strong> a ausência
            de: <strong>{ausenciaToDelete?.nome}</strong>?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseConfirmDelete}>
              Cancelar
            </Button>
            <Button
              style={{
                backgroundColor: "#fca903",
                borderColor: "#fca903",
                color: "#fff",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) =>
                (e.currentTarget.style.backgroundColor = "#e59202")
              }
              onMouseOut={(e) =>
                (e.currentTarget.style.backgroundColor = "#fca903")
              }
              onClick={handleDeleteAusencia}
            >
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Ausencia;
