import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "./components/UserContext";

interface RotasComPermissaoProps {
  requiredRoles?: string[];
  requiredAreas?: string[];
  requiredUsuarioCidade?: "Sim" | "Não";
  requiredAdvogadoExterno?: "Sim" | "Não";
  requiredLiderSquad?: boolean;
  requiredLiderOuAdmin?: boolean;
  children: React.ReactNode;
}

const RotasComPermissao: React.FC<RotasComPermissaoProps> = ({
  requiredRoles,
  requiredAreas,
  requiredUsuarioCidade,
  requiredAdvogadoExterno,
  requiredLiderOuAdmin,
  children,
}) => {
  const { user } = useContext(UserContext);

  // Obter o usuário do localStorage como fallback
  const storedUser = localStorage.getItem("user");
  const currentUser = user || (storedUser ? JSON.parse(storedUser) : null);

  if (!currentUser) {
    console.log("Usuário não autenticado. Redirecionando para /login.");
    return <Navigate to="/login" replace />;
  }

  const {
    area,
    funcao,
    usuario_cidade,
    advogado_externo,
    isLiderSquad,
    isAdmin,
  } = currentUser;

  // Transformar as áreas do usuário em array
  const userAreas = area ? area.split(",").map((a: string) => a.trim()) : [];

  const areaPermitida =
    !requiredAreas || requiredAreas.length === 0
      ? true
      : userAreas.some((userArea: string) => requiredAreas.includes(userArea));

  const funcaoPermitida =
    !requiredRoles || requiredRoles.length === 0
      ? true
      : requiredRoles.includes(funcao);

  const usuarioCidadeOk =
    requiredUsuarioCidade === undefined
      ? true
      : usuario_cidade === requiredUsuarioCidade;

  const advogadoExternoOk =
    requiredAdvogadoExterno === undefined
      ? true
      : advogado_externo === requiredAdvogadoExterno;

  const liderOuAdminPermitido =
    requiredLiderOuAdmin === true ? isLiderSquad || isAdmin : true;

  // Combine todas as condições
  const permitido =
    areaPermitida &&
    funcaoPermitida &&
    usuarioCidadeOk &&
    advogadoExternoOk &&
    liderOuAdminPermitido;

  if (permitido) {
    return <>{children}</>;
  } else {
    console.log("Permissão negada. Redirecionando para /.");
    return <Navigate to="/" replace />;
  }
};

export default RotasComPermissao;
