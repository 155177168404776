//LCORREA - SSI 0058 - 11/09/2024 - Correção da assembleia para quando for AGI
// GCOLLE - SSI 0070 - 24/10/24 - Alterar highlights e enviar link_anexo .emit

import React, { useEffect, useState, useContext, useMemo } from "react";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import UserContext from "components/UserContext";
import customParseFormat from "dayjs/plugin/customParseFormat";
// Importação agregada dos componentes do MUI
import {
  TextField,
  IconButton,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import PublishIcon from "@mui/icons-material/Publish";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AddIcon from "@mui/icons-material/Add";
import EditNoteIcon from "@mui/icons-material/EditNote";
import axios from "axios";
import io from "socket.io-client";
import AssembleiaStatus from "./AssembleiaStatus";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import businessDays from "dayjs-business-days";

import PageBreadcrumb from "components/common/PageBreadcrumb"; //SSI 0093 - MATHEUS BOTELHO - 15/01/2025
import SearchBox from "components/common/SearchBox"; //SSI 0093 - MATHEUS BOTELHO - 15/01/2025

//SSI 0093 - MATHEUS BOTELHO - 16/01/2025 - INICIO

import useAdvanceTable from "hooks/useAdvanceTable";
import AdvanceTableProvider from "providers/AdvanceTableProvider";
import AdvanceTable from "components/base/AdvanceTable";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Badge from "components/base/Badge";

import { Edit } from "feather-icons-react";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

//SSI 0093 - MATHEUS BOTELHO - 16/01/2025 - FIM

dayjs.extend(customParseFormat);
dayjs.extend(businessDays);
dayjs.locale("pt-br");

import {
  Button,
  Row,
  Col,
  Modal,
  Alert,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";

const Assembleia = () => {
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [condominios, setCondominios] = useState([]);
  const [filtro, setFiltro] = useState("");
  const [condominioId, setCondominioId] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [dataAtual, setDataAtual] = useState(dayjs());
  const [condominio, setCondominio] = useState(null);
  const [tipoAssembleia, setTipoAssembleia] = useState(null);
  const [formatoAssembleia, setFormatoAssembleia] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [assembleias, setAssembleias] = useState([]);
  const [periodoSelecionado, setPeriodoSelecionado] = useState(dayjs());
  const [diasConvocacao, setDiasConvocacao] = useState("");
  const [dataConvocacao, setDataConvocacao] = useState("");
  const [editavel, setEditavel] = useState(false);
  const [order, setOrder] = useState({ field: "condominio", direction: "asc" });
  const [filtroTexto, setFiltroTexto] = useState("");
  const [filtroFormato, setFiltroFormato] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [secaoExpandida, setSecaoExpandida] = useState("campos");
  const [checklistStatus, setChecklistStatus] = useState(Array(15).fill(false));
  const [assembleiaAtual, setAssembleiaAtual] = useState(null);
  const [showAuxiliar, setShowAuxiliar] = useState(false);
  const [secretarios, setSecretarios] = useState([]);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [activeUsers, setActiveUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [idFuncAuxiliar, setIdFuncAuxiliar] = useState("");
  const [isGoogleEventCreated, setIsGoogleEventCreated] = useState(false);
  const [dataSelecionada, setDataSelecionada] = useState(
    dayjs().format("DD-MM-YYYY"),
  );
  const [eventos, setEventos] = useState([]);
  const nomeDoDia = dayjs(dataSelecionada, "DD-MM-YYYY").format("dddd");
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [isAssemblyPublished, setIsAssemblyPublished] = useState(false);
  const [isMinutesPublished, setIsMinutesPublished] = useState(false);
  const [filteredAssembleias, setFilteredAssembleias] = useState([]);
  const [mostrarTodos, setMostrarTodos] = useState(true);
  const [socket, setSocket] = useState(null); // Adicionado useState para socket
  const [groupedUserOptions, setGroupedUserOptions] = useState({});
  const [selectedUser, setSelectedUser] = useState("");
  const [tipoDia, setTipoDia] = useState("corridos");
  const [showAlertPrevia, setShowAlertPrevia] = useState(false);
  const [alertMessagePrevia, setAlertMessagePrevia] = useState("");

  const [anoSelecionado, setAnoSelecionado] = useState(
    new Date().getFullYear(),
  );
  const [mesSelecionado, setMesSelecionado] = useState(
    new Date().getMonth() + 1,
  );

  const [mostrarFinalizadas, setMostrarFinalizadas] = useState(true);

  //SSI 0093 - MATHEUS BOTELHO - 15/01/2025 - INICIO

  const defaultBreadcrumbItems = [
    { label: "Home", link: "/" },
    { label: "Lançamentos Financeiro", active: true },
  ];

  //SSI 0093 - MATHEUS BOTELHO - 15/01/2025 - FIM

  // Função para redefinir os estados dos campos
  const resetFormFields = () => {
    setCondominio(null);
    setSelectedDate(dayjs()); // Reinicializa para a data atual ou qualquer outra lógica de inicialização que deseje
    setTipoAssembleia(""); // Certifique-se de que o valor inicial corresponda ao esperado pelo seu componente
    setFormatoAssembleia(""); // Similarmente, um valor inicial adequado
  };

  const openModal = async () => {
    setIsModalOpen(true);
    await fetchCondominios();
    resetFormFields(); // Chama a função para redefinir os campos ao abrir o modal
  };

  // Supondo que você tenha uma função para fechar o modal
  const closeModal = () => {
    setIsModalOpen(false);
    resetFormFields(); // Também redefinir os campos ao fechar para garantir que o modal reabra limpo na próxima vez
  };

  const fetchCondominios = async () => {
    const url = `${API_URL}/assembleia/condominios`;
    //console.log("Tentando acessar a URL:", url);
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setCondominios(data);
        console.log("Condomínios recebidos:", data);
      } else {
        console.error("Erro ao buscar condomínios:", response.statusText);
      }
    } catch (error) {
      console.error("Erro na requisição para:", url, error);
    }
  };

  const condominiosFiltrados = condominios.filter((condominio) =>
    condominio.fantasia.toLowerCase().includes(filtro),
  );

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (editavel) {
      toast.warn("Confirme a data de convocação antes de prosseguir.");
      return;
    }

    if (
      !condominio ||
      !tipoAssembleia ||
      !formatoAssembleia ||
      !selectedDate ||
      !dataConvocacao
    ) {
      toast.warn("Preencha todos os campos antes de prosseguir.");
      return;
    }

    const dataConvocacaoFormatada =
      dataConvocacao && dataConvocacao.includes("/")
        ? dataConvocacao.split("/").reverse().join("-")
        : "";

    const formData = {
      condominio: condominio ? condominio.fantasia : "",
      id_cond: condominio ? condominio.chave : "",
      dt_assembleia: selectedDate.format("YYYY-MM-DD"),
      hr_assembleia: "00:00:00",
      dt_convocacao: dataConvocacaoFormatada,
      tipo_assembleia: tipoAssembleia,
      formato_assembleia: formatoAssembleia,
      nome_responsavel: null,
      id_func_responsavel: null,
      nome_auxiliar: null,
      id_func_auxiliar: null,
      assuntos_assembleia: null,
      anexo_assembleia: null,
      status_assembleia: 1,
      tipo_dia: tipoDia,
      id_func_criador: user?.id_func, // Adicionado aqui
    };

    try {
      const response = await fetch(`${API_URL}/assembleia/cadastrar`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        toast.success("Assembleia cadastrada com sucesso!");
        closeModal();
        const anoAtual = new Date().getFullYear();
        const mesAtual = new Date().getMonth() + 1;
        fetchAssembleiasPorPeriodo(anoAtual, mesAtual);
      } else {
        const errorText = await response.text();
        toast.error(`Erro ao cadastrar assembleia: ${errorText}`);
      }
    } catch (error) {
      toast.error(`Erro na requisição: ${error.message}`);
    }
  };

  const fetchDiasConvocacao = async (idCond) => {
    const url = `${API_URL}/assembleia/dias_convocacao/${idCond}`;
    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        const dias =
          data.dias_convocacao !== null ? data.dias_convocacao.toString() : "";
        setDiasConvocacao(dias);
        // GCOLLE - 0070 - 24/10/24 - TipoDia
        setTipoDia(data.tipo_dia || "corridos");
        setEditavel(!dias);
      } else {
        setDiasConvocacao("");
        setEditavel(true);
      }
    } catch (error) {
      console.error("Erro ao buscar dias de convocação:", error);
      setDiasConvocacao("");
      setEditavel(true);
    }
  };

  const atualizarDataConvocacao = (tipoDia, diasConvocacao, selectedDate) => {
    if (selectedDate && diasConvocacao) {
      let diasParaSubtrair = parseInt(diasConvocacao);
      let dataAssembleia = dayjs(selectedDate);
      let dataDaConvocacao = dataAssembleia;

      if (tipoDia === "corridos") {
        dataDaConvocacao = dataAssembleia.subtract(diasParaSubtrair, "day");
      } else {
        while (diasParaSubtrair > 0) {
          dataDaConvocacao = dataDaConvocacao.subtract(1, "day");
          if (dataDaConvocacao.day() !== 0 && dataDaConvocacao.day() !== 6) {
            diasParaSubtrair--;
          }
        }
      }

      setDataConvocacao(dataDaConvocacao.format("DD/MM/YYYY"));
    } else {
      setDataConvocacao("");
    }
  };

  const salvarDiasConvocacao = async () => {
    const url = `${API_URL}/assembleia/atualizar_dias_convocacao/${condominio.chave}`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dias_convocacao: diasConvocacao,
          tipo_dia: tipoDia, // GCOLLE - 0070 - Incluindo o tipo de dia na atualização
        }),
      });
      if (response.ok) {
        atualizarDataConvocacao(tipoDia, diasConvocacao, selectedDate);
      } else {
        console.error("Erro ao atualizar dias para convocação.");
      }
    } catch (error) {
      console.error("Erro ao tentar atualizar dias para convocação:", error);
    }
    setEditavel(false);
  };

  useEffect(() => {
    atualizarDataConvocacao(tipoDia, diasConvocacao, selectedDate);
  }, [selectedDate, diasConvocacao, tipoDia]);

  // Fim - Processos para o modal de cadastro de assembleias

  const formataData = (data) => {
    return data.format("MMM YYYY"); // Formata como 'Abr 2024' para abril de 2024, por exemplo
  };

  const proximoMes = () => {
    setDataAtual(dataAtual.add(1, "month"));
  };

  const mesAnterior = () => {
    setDataAtual(dataAtual.subtract(1, "month"));
  };

  // PROCESSOS PARA A TABELA

  // SSI 0041 - 19/07/24

  //SSI 0093 - MATHEUS BOTELHO - 21/01/25 - INICIO

  useEffect(() => {
    // Se `dataAtual` não estiver definido corretamente, usamos o período atual como padrão
    const today = dayjs();
    const ano = dataAtual ? dataAtual.year() : today.year();
    const mes = dataAtual ? dataAtual.month() + 1 : today.month() + 1;

    // Chama a função para buscar assembleias do período
    fetchAssembleiasPorPeriodo(ano, mes);
  }, [dataAtual, user]);

  const fetchAssembleiasPorPeriodo = async (ano, mes) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${API_URL}/assembleia/assembleias/${ano}/${mes}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Dados brutos recebidos:", data);

        const formattedData = data.map((assembleia) => ({
          ...assembleia,
          dt_convocacao: formatDate(assembleia.dt_convocacao),
          dt_assembleia: formatDate(assembleia.dt_assembleia),
          dt_criacao: formatDate(assembleia.dt_criacao),
          dt_publicacao: formatDate(assembleia.dt_publicacao),
          statusData: getStatusData(assembleia.dt_convocacao),
        }));

        setAssembleias(formattedData);
        setAnoSelecionado(ano);
        setMesSelecionado(mes);

        console.log("Assembleias formatadas recebidas:", formattedData);
      } else {
        console.error("Erro ao buscar assembleias:", response.statusText);
        setAssembleias([]);
      }
    } catch (error) {
      console.error("Erro na requisição para buscar assembleias:", error);
      setAssembleias([]);
    }
  };

  const getStatusData = (dateString) => {
    // Adicionar log para verificar o formato da data de convocação
    //console.log("Data de convocação recebida:", dateString);

    const today = dayjs().startOf("day");
    // Converter a data de 'dd/MM/yyyy' para 'YYYY-MM-DD'
    const [day, month, year] = dateString.split("/");
    const date = dayjs(`${year}-${month}-${day}`).startOf("day");

    // Adicionar log para verificar a comparação
    //console.log("Comparando:", {
    //	today: today.format("YYYY-MM-DD"),
    //	date: date.format("YYYY-MM-DD"),
    //	isSameDay: date.isSame(today, "day"),
    //	isBefore: date.isBefore(today, "day"),
    //});

    if (date.isSame(today, "day")) {
      return "no dia";
    } else if (date.isBefore(today, "day")) {
      return "atrasado";
    } else {
      return "no prazo";
    }
  };

  //FIM DA ALTERAÇÃO

  const fetchCondominiosPorUsuario = async (id_func, assembleias) => {
    try {
      const response = await fetch(
        `${API_URL}/assembleia/condominios/${id_func}`,
      );

      if (response.ok) {
        const idConds = await response.json();
        //console.log("ID_COND recebidos:", idConds);
        const filtered = assembleias.filter((assembleia) =>
          idConds.includes(assembleia.id_cond),
        );
        setFilteredAssembleias(filtered);
      } else {
        console.error(
          "Erro ao buscar condomínios do agente:",
          response.statusText,
        );
        setFilteredAssembleias([]);
      }
    } catch (error) {
      console.error(
        "Erro na requisição para buscar condomínios do agente:",
        error,
      );
      setFilteredAssembleias([]);
    }
  };

  const displayedAssembleias = useMemo(() => {
    console.log("applyFilters() sendo executado...");

    let filtered = mostrarTodos ? assembleias : filteredAssembleias;

    // Filtra as assembleias com status 16 (finalizadas) se mostrarFinalizadas for falso
    if (!mostrarFinalizadas) {
      filtered = filtered.filter((assembleia) => {
        const status = parseInt(assembleia.status_assembleia, 10); // Converte para número
        return status !== 16; // Compara corretamente como número
      });
    }

    console.log("Assembleias após aplicar filtro:", filtered);
    return filtered;
  }, [assembleias, filteredAssembleias, mostrarTodos, mostrarFinalizadas]);

  const handleToggleTodos = () => {
    const proximoValor = !mostrarTodos;
    setMostrarTodos(proximoValor);

    if (!proximoValor && user.funcao === "Agente") {
      console.log("Carregando assembleias filtradas para o agente");
      fetchCondominiosPorUsuario(user.id_func, assembleias)
        .then(() => {
          console.log("Filtro aplicado com sucesso.");
        })
        .catch((error) => {
          console.error("Erro ao filtrar assembleias:", error);
        });
    } else {
      console.log("Mostrando todas as assembleias.");
    }
  };

  //SSI 0093 - MATHEUS BOTELHO - 21/01/25 - FIM

  const formatDate = (dateString) => {
    if (!dateString || dateString === "00/00/0000") return "-";
    const [day, month, year] = dateString.split("/");
    return `${day}/${month}/${year}`;
  };

  // PROCESSOS PARA O MODAL DE EDIÇÃO DE ASSEMBLEIAS

  const abrirModalEdicao = async (dadosAssembleia) => {
    setIsLoading(true);
    //console.log("Dados recebidos para edição:", dadosAssembleia);

    try {
      const secretariosResponse = await fetch(
        `${API_URL}/assembleia/assembleia/usuarios`,
      );
      if (secretariosResponse.ok) {
        const secretarios = await secretariosResponse.json();
        setSecretarios(secretarios);
        //console.log("Secretários carregados:", secretarios);
      }

      // Configura o estado inicial com os IDs dos responsáveis
      setAssembleiaAtual({
        ...dadosAssembleia,
        id_func_responsavel: dadosAssembleia.id_func_responsavel,
        id_func_auxiliar: dadosAssembleia.id_func_auxiliar,
        anexo_assembleia: dadosAssembleia.anexo_assembleia, // Certifique-se de que este campo está sendo setado
        nome_arquivo: dadosAssembleia.nome_arquivo, // Certifique-se de que este campo está sendo setado
      });

      setShowAuxiliar(!!dadosAssembleia.nome_auxiliar);
      const statusIndex = parseInt(dadosAssembleia.status_assembleia, 10);
      const newChecklistStatus = checklistStatus.map(
        (_, index) => index < statusIndex,
      );
      setChecklistStatus(newChecklistStatus);
      setIsEditModalOpen(true);
    } catch (error) {
      console.error("Erro na requisição:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fecharModalEdicao = () => {
    setIsEditModalOpen(false);
    fetchAssembleiasPorPeriodo(anoSelecionado, mesSelecionado); // Use os estados atuais
  };

  const alternarSecaoExpandida = (secao) => {
    const novaSecao = secaoExpandida === secao ? null : secao;
    //console.log(`Alterando seção expandida para: ${novaSecao}`);
    setSecaoExpandida(novaSecao);
  };

  const handleChecklistChange = (index) => {
    const lastIndexChecked = checklistStatus.lastIndexOf(true); // Último index marcado
    if (index === lastIndexChecked || index === lastIndexChecked + 1) {
      const updatedStatus = [...checklistStatus];
      updatedStatus[index] = !updatedStatus[index];
      setChecklistStatus(updatedStatus);
    }
  };

  const checklistItems = [
    "Assembleia criada",
    "Envio da Convocação",
    "Envio Lembrete",
    "Confirmação Equipamento",
    "Confirmação Documentos",
    "Confirmação Inadimplência",
    "Publicação Interna Ata",
    "Publicação Externa Ata",
    "Alteração Responsáveis Legais",
    "Alteração Cadastro Octadesk",
    "Tarefa de alteração de síndico",
    "Coleta de Assinaturas",
    "Envio para Registro",
    "Publicação Ata Registrada",
    "Envio Tarefa de alteração financeiro",
    "Assembleia Finalizada",
  ];

  const getStatusDescription = (status) => {
    return checklistItems[status - 1] || "Status desconhecido";
  };

  const fetchAssembleiaDetalhes = async (id) => {
    try {
      // Verifica e atualiza o token
      const tokenResponse = await fetch(
        `${API_URL}/assembleia/verificar-token`,
      );
      const tokenData = await tokenResponse.json();

      if (tokenData.success) {
        const response = await fetch(`${API_URL}/assembleia/detalhes/${id}`);
        if (response.ok) {
          const data = await response.json();

          // Atualiza o estado da assembleia atual com os detalhes recebidos
          setAssembleiaAtual(data);

          // Formata a data da assembleia para exibição
          const dataAssembleia = dayjs(data.dt_assembleia).format("DD-MM-YYYY");
          setDataSelecionada(dataAssembleia);

          // Abre o modal de edição com os detalhes da assembleia
          abrirModalEdicao(data);

          // Carrega eventos relacionados à data da assembleia
          carregarEventos(dataAssembleia);

          // Verifica se todos os campos necessários estão preenchidos
          const isDataComplete = verificaCamposNecessarios(data);
          setIsDataSaved(isDataComplete);

          // Atualiza o estado de publicação da assembleia e da ata
          setIsAssemblyPublished(data.assembleia_publicada === 1);
          setIsMinutesPublished(data.ata_publicada === 1);
        } else {
          console.error("Erro ao buscar detalhes da assembleia");
        }
      } else {
        console.error(
          "Erro ao verificar e atualizar o token:",
          tokenData.message,
        );
      }
    } catch (error) {
      console.error(
        "Erro na requisição para buscar detalhes da assembleia:",
        error,
      );
    }
  };

  const handleUserSelect = (event) => {
    setSelectedUser(event.target.value);
    if (event.target.value) {
      addUserToAssembly(event.target.value);
    }
  };

  const handleAddAuxiliary = () => {
    setShowAuxiliar(!showAuxiliar);
  };

  //alteracao leo
  useEffect(() => {
    if (isUserModalOpen) {
      const fetchAllUsers = async () => {
        try {
          const response = await fetch(`${API_URL}/assembleia/usuarios/ativos`);
          if (response.ok) {
            const users = await response.json();
            //("Fetched Users:", users); // Log para verificar os usuários retornados
            // Agrupar usuários por cidade
            const groupedUsers = users.reduce((acc, user) => {
              const group = user.cidade_sede || "Sem cidade sede";
              if (!acc[group]) {
                acc[group] = [];
              }
              acc[group].push(user);
              return acc;
            }, {});
            //console.log("Grouped Users:", groupedUsers); // Log para verificar os usuários agrupados
            // Ordenar os usuários dentro de cada grupo
            Object.keys(groupedUsers).forEach((key) => {
              groupedUsers[key].sort((a, b) => a.nome.localeCompare(b.nome));
            });
            //console.log("Sorted Grouped Users:", groupedUsers); // Log para verificar os usuários agrupados e ordenados

            // Ordenar os grupos
            const sortedGroups = Object.keys(groupedUsers).sort((a, b) => {
              if (a === "Maringá") return -1;
              if (b === "Maringá") return 1;
              if (a === "Sem cidade sede") return 1;
              if (b === "Sem cidade sede") return -1;
              return a.localeCompare(b);
            });

            const orderedGroupedUsers = sortedGroups.reduce((acc, key) => {
              acc[key] = groupedUsers[key];
              return acc;
            }, {});

            setGroupedUserOptions(orderedGroupedUsers);
          } else {
            console.error("Failed to fetch users:", response.status);
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
      fetchAllUsers();
    }
  }, [isUserModalOpen, API_URL]);

  const fetchActiveUsers = async () => {
    const response = await fetch(`${API_URL}/assembleia/assembleia/usuarios`);
    if (response.ok) {
      const users = await response.json();
      setActiveUsers(users);
    } else {
      console.error("Failed to fetch active users");
    }
  };

  const addUserToAssembly = async (userId) => {
    const response = await fetch(`${API_URL}/assembleia/assembleia/add-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_func: userId }),
    });

    if (response.ok) {
      //console.log("User added successfully");
      fetchActiveUsers(); // Refresh the list after adding
    } else {
      console.error("Error adding user");
    }
  };

  const handleOpenUserModal = () => {
    setIsUserModalOpen(true);
    fetchActiveUsers();
  };

  const fetchSecretarios = async () => {
    try {
      const response = await fetch(`${API_URL}/assembleia/assembleia/usuarios`);
      if (response.ok) {
        const secretariosAtualizados = await response.json();
        setSecretarios(secretariosAtualizados);
      } else {
        console.error("Erro ao buscar secretários");
      }
    } catch (error) {
      console.error("Erro na requisição para buscar secretários:", error);
    }
  };

  const handleCloseUserModal = () => {
    setIsUserModalOpen(false);
    fetchSecretarios(); // Atualiza a lista de secretários após fechar o modal de usuários
  };

  const handleAddSecretary = () => {
    // Esta função irá alternar a visibilidade de um campo para adicionar um auxiliar
    setShowAuxiliar(!showAuxiliar); // Alterna a visibilidade do campo auxiliar
  };

  const removeUserFromAssembly = async (userId) => {
    const response = await fetch(
      `${API_URL}/assembleia/assembleia/remove-user`, // Ajuste para a nova rota
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id_func: userId }),
      },
    );

    if (response.ok) {
      //console.log("User removed successfully");
      fetchActiveUsers(); // Refresh the list after removing
    } else {
      console.error("Error removing user", await response.text());
    }
  };

  const handleSave = async () => {
    const dadosCompletos = verificaCamposNecessarios(assembleiaAtual);

    let novoStatus = assembleiaAtual.status_assembleia;
    const lastCheckedIndex = checklistStatus.lastIndexOf(true);
    if (lastCheckedIndex >= 0 && lastCheckedIndex + 1 > novoStatus) {
      novoStatus = lastCheckedIndex + 1;
    }

    // Log do horário antes do ajuste
    console.log(
      "Valor inicial de hr_assembleia:",
      assembleiaAtual.hr_assembleia,
    );

    const hrAssembleia = assembleiaAtual.hr_assembleia.includes(":")
      ? assembleiaAtual.hr_assembleia
      : `${assembleiaAtual.hr_assembleia}:00`;

    const hrAssembleiaComSegundos =
      hrAssembleia.length === 5 ? `${hrAssembleia}:00` : hrAssembleia;

    // Log do horário após o ajuste
    console.log("Valor ajustado de hr_assembleia:", hrAssembleiaComSegundos);

    const dataHoraAlteracao = dayjs()
      .tz("America/Sao_Paulo")
      .format("YYYY-MM-DD HH:mm:ss"); //SSI 0093 - MATHEUS BOTELHO - 21/01/25

    const updatedAssembleia = {
      ...assembleiaAtual,
      status_assembleia: novoStatus,
      dados_salvos: dadosCompletos ? 1 : 0,
      hr_assembleia: hrAssembleiaComSegundos,
      observacoes: assembleiaAtual.observacoes, // Adicionado o campo observacoes
      //SSI 0093 - MATHEUS BOTELHO - 21/01/25 - INICIO
      usuario_alteracao: {
        id_func: user.id_func, // ID do usuário
        nome: user.nome, // Nome do usuário
        data_hora: dataHoraAlteracao, // Data e hora da alteração
      },
      //SSI 0093 - MATHEUS BOTELHO - 21/01/25 - FIM
    };

    // Log dos dados que serão enviados para o backend
    console.log("Dados enviados para o backend:", updatedAssembleia);

    const checkPublishedUrl = `${API_URL}/assembleia/check-published/${assembleiaAtual.id}`;

    try {
      const publishedResponse = await fetch(checkPublishedUrl);
      const { isPublished, eventId } = await publishedResponse.json();

      const url = `${API_URL}/assembleia/atualizar`;
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(updatedAssembleia),
      });

      if (response.ok) {
        setIsDataSaved(dadosCompletos);
        toast.success("Dados salvos com sucesso!");

        if (isPublished && eventId) {
          const startDate = dayjs(assembleiaAtual.dt_assembleia).format(
            "YYYY-MM-DD",
          );
          const startTime = `${startDate}T${hrAssembleiaComSegundos}`;
          const endTime = dayjs(startTime)
            .add(30, "minutes")
            .format("YYYY-MM-DDTHH:mm:ss");

          const locationUrl = `${API_URL}/assembleia/location/${assembleiaAtual.id_cond}`;
          const locationResponse = await fetch(locationUrl);
          const locationData = await locationResponse.json();

          let cidade = "";
          let estado = "";
          let location = "Local a ser definido";

          if (locationData && locationData.cidade && locationData.estado) {
            cidade = locationData.cidade;
            estado = locationData.estado;
            location = `${cidade}, ${estado}`;
          }

          if (assembleiaAtual.formato_assembleia === "Remota") {
            location = `Online (${cidade}, ${estado})`;
          }

          const tipoAssembleiaSigla =
            assembleiaAtual.tipo_assembleia === "Extraordinária"
              ? "AGE"
              : assembleiaAtual.tipo_assembleia === "Permanente"
                ? "PERM"
                : assembleiaAtual.tipo_assembleia === "Instalação" //LCORREA - SSI 0058 - 11/09/2024
                  ? "AGI" //LCORREA - SSI 0058 - 11/09/2024
                  : "AGO"; //LCORREA - SSI 0058 - 11/09/2024

          const responsavel =
            assembleiaAtual.nome_responsavel || "Sem responsável";
          const auxiliar = assembleiaAtual.nome_auxiliar || "";

          const sindicoResponse = await fetch(
            `${API_URL}/assembleia/sindico/${assembleiaAtual.id_cond}`,
          );
          if (!sindicoResponse.ok) {
            toast.error("Erro ao buscar os e-mails do síndico");
            console.error("Erro ao buscar os e-mails do síndico");
            return;
          }
          const sindicoData = await sindicoResponse.json();

          let secretarioEmail = null;
          if (assembleiaAtual.id_func_responsavel) {
            const secretarioResponse = await fetch(
              `${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_responsavel}`,
            );
            if (secretarioResponse.ok) {
              const secretarioData = await secretarioResponse.json();
              secretarioEmail = secretarioData.email;
            }
          }

          let auxiliarEmail = null;
          if (assembleiaAtual.id_func_auxiliar) {
            const auxiliarResponse = await fetch(
              `${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_auxiliar}`,
            );
            if (auxiliarResponse.ok) {
              const auxiliarData = await auxiliarResponse.json();
              auxiliarEmail = auxiliarData.email;
            }
          }

          const emails = [
            ...sindicoData.emails,
            secretarioEmail,
            auxiliarEmail,
          ].filter(Boolean);

          const calendarUpdateUrl = `${API_URL}/assembleia/editar-evento`;
          const eventUpdates = {
            id: eventId,
            updates: {
              condominio: assembleiaAtual.condominio,
              formato_assembleia: assembleiaAtual.formato_assembleia,
              tipo_assembleia: assembleiaAtual.tipo_assembleia,
              nome_responsavel: assembleiaAtual.nome_responsavel,
              nome_auxiliar: assembleiaAtual.nome_auxiliar,
              summary: `${
                assembleiaAtual.condominio
              } - ${tipoAssembleiaSigla} - ${
                assembleiaAtual.formato_assembleia
              } (${responsavel}${auxiliar ? ` - ${auxiliar}` : ""})`,
              location: location,
              description:
                assembleiaAtual.assuntos_assembleia || "Sem pautas definidas",
              start: {
                dateTime: startTime,
                timeZone: "America/Sao_Paulo",
              },
              end: {
                dateTime: endTime,
                timeZone: "America/Sao_Paulo",
              },
              attendees: emails.map((email) => ({ email })),
            },
            sendUpdates: "all",
          };

          const calendarResponse = await fetch(calendarUpdateUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(eventUpdates),
          });

          if (calendarResponse.ok) {
            toast.success("Evento do Google Calendar atualizado com sucesso!");
          } else {
            const calendarErrorText = await calendarResponse.text();
            toast.error(
              `Erro ao atualizar o evento do Google Calendar: ${calendarErrorText}`,
            );
            console.error(
              "Erro ao atualizar o evento do Google Calendar:",
              calendarErrorText,
            );
          }
        }
      } else {
        const errorText = await response.text();
        toast.error(`Erro ao salvar os dados: ${errorText}`);
        console.error("Erro ao salvar os dados:", errorText);
      }
    } catch (error) {
      toast.error(`Erro ao fazer a requisição: ${error.message}`);
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  const handleDelete = async () => {
    try {
      if (isAssemblyPublished) {
        const checkPublishedUrl = `${API_URL}/assembleia/check-published/${assembleiaAtual.id}`;
        const publishedResponse = await fetch(checkPublishedUrl);
        const { isPublished, eventId } = await publishedResponse.json();

        if (isPublished && eventId) {
          const deleteUrl = `${API_URL}/assembleia/excluir-evento`;
          const response = await fetch(deleteUrl, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ eventId }),
          });

          if (!response.ok) {
            toast.error("Erro ao excluir assembleia no Google Calendar");
            return;
          }
        }
      }

      const deleteAssembleiaUrl = `${API_URL}/assembleia/excluir-assembleia`;
      const deleteAssembleiaResponse = await fetch(deleteAssembleiaUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id: assembleiaAtual.id }),
      });

      if (deleteAssembleiaResponse.ok) {
        setIsAssemblyPublished(false);
        fecharModalEdicao();
        setAssembleias((prevAssembleias) =>
          prevAssembleias.filter(
            (assembleia) => assembleia.id !== assembleiaAtual.id,
          ),
        );
        toast.success("Assembleia excluída com sucesso!");
      } else {
        toast.error("Erro ao excluir assembleia do banco de dados");
      }
    } catch (error) {
      toast.error(`Erro ao excluir a assembleia: ${error.message}`);
    }
  };

  const verificaCamposNecessarios = (assembleia) => {
    const camposNecessarios = [
      "condominio",
      "dt_assembleia",
      "dt_convocacao",
      "tipo_assembleia",
      "formato_assembleia",
      "nome_responsavel",
      "id_func_responsavel",
      "id_func_auxiliar",
      "assuntos_assembleia",
    ];

    return camposNecessarios.every((campo) => {
      const valor = assembleia[campo];
      if (typeof valor === "string") {
        return valor.trim() !== "";
      } else {
        return valor !== undefined && valor !== null;
      }
    });
  };

  // Publicar assembleia
  const handlePublish = async () => {
    try {
      if (!assembleiaAtual || !assembleiaAtual.id_cond) {
        toast.error(
          "Assembleia atual ou ID do condomínio não estão definidos.",
        );
        return;
      }

      const sindicoResponse = await fetch(
        `${API_URL}/assembleia/sindico/${assembleiaAtual.id_cond}`,
      );
      if (!sindicoResponse.ok) {
        toast.error("Erro ao buscar os e-mails do síndico");
        return;
      }
      const sindicoData = await sindicoResponse.json();

      let secretarioEmail = null;
      if (assembleiaAtual.id_func_responsavel) {
        const secretarioResponse = await fetch(
          `${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_responsavel}`,
        );
        if (secretarioResponse.ok) {
          const secretarioData = await secretarioResponse.json();
          secretarioEmail = secretarioData.email;
        }
      }

      let auxiliarEmail = null;
      if (assembleiaAtual.id_func_auxiliar) {
        const auxiliarResponse = await fetch(
          `${API_URL}/assembleia/email_usuario/${assembleiaAtual.id_func_auxiliar}`,
        );
        if (auxiliarResponse.ok) {
          const auxiliarData = await auxiliarResponse.json();
          auxiliarEmail = auxiliarData.email;
        }
      }

      const emails = [
        ...sindicoData.emails,
        secretarioEmail,
        auxiliarEmail,
      ].filter(Boolean);

      //LCORREA
      const tipoAssembleiaSigla =
        assembleiaAtual.tipo_assembleia === "Extraordinária"
          ? "AGE"
          : assembleiaAtual.tipo_assembleia === "Permanente"
            ? "PERM"
            : assembleiaAtual.tipo_assembleia === "Instalação" //LCORREA - SSI 0058 - 11/09/2024
              ? "AGI" //LCORREA - SSI 0058 - 11/09/2024
              : "AGO"; //LCORREA - SSI 0058 - 11/09/2024

      const responsavel = assembleiaAtual.nome_responsavel || "Sem responsável";
      const auxiliar = assembleiaAtual.nome_auxiliar || "";

      const publishResponse = await fetch(
        `${API_URL}/assembleia/publicar-assembleia`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            id: assembleiaAtual.id,
            emails,
            tipoAssembleia: assembleiaAtual.tipo_assembleia,
            tipoAssembleiaSigla,
            responsavel,
            auxiliar,
            id_func_publicador: user.id_func,
          }),
        },
      );

      if (!publishResponse.ok) {
        toast.error("Erro ao publicar assembleia");
        return;
      }

      const publishData = await publishResponse.json();
      setAssembleias((prevAssembleias) =>
        prevAssembleias.map((assembleia) =>
          assembleia.id === assembleiaAtual.id
            ? { ...assembleia, assembleia_publicada: true }
            : assembleia,
        ),
      );

      setIsAssemblyPublished(true);
      toast.success("Assembleia publicada com sucesso!");
    } catch (error) {
      toast.error(`Erro ao publicar assembleia: ${error.message}`);
    }
  };

  const removerAnexo = async () => {
    try {
      const response = await fetch(`${API_URL}/assembleia/remover-anexo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: assembleiaAtual.id,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        setAssembleiaAtual({
          ...assembleiaAtual,
          anexo_assembleia: null,
          nome_arquivo: null,
        });
        toast.success("Anexo removido com sucesso!");
      } else {
        toast.error(`Falha ao remover anexo: ${data.message}`);
      }
    } catch (error) {
      toast.error(`Erro ao remover anexo: ${error.message}`);
    }
  };

  const atualizarAnexo = async (fileData, fileName) => {
    try {
      const response = await fetch(`${API_URL}/assembleia/atualizar-anexo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: assembleiaAtual.id,
          anexo_assembleia: fileData,
          nome_arquivo: fileName,
        }),
      });

      if (response.ok) {
        setAssembleiaAtual({
          ...assembleiaAtual,
          anexo_assembleia: fileData,
          nome_arquivo: fileName,
        });
        toast.success("Anexo atualizado com sucesso!");
      } else {
        const errorText = await response.text();
        toast.error(`Falha ao atualizar anexo: ${errorText}`);
      }
    } catch (error) {
      toast.error(`Erro ao atualizar anexo: ${error.message}`);
    }
  };

  useEffect(() => {
    const newSocket = io(`${API_URL}`);
    setSocket(newSocket);
    newSocket.on("errorNotification", (errorMessage) => {
      //console.log("Received error notification:", errorMessage);
    });
    return () => newSocket.close();
  }, []);

  async function publicarAta() {
    const loadingToastId = toast.loading("Publicando ATA..."); // Toast de loading

    try {
      if (!assembleiaAtual) {
        toast.error("Nenhuma assembleia selecionada.");
        toast.dismiss(loadingToastId); // Remove o loading toast
        return;
      }

      const assembleiaId = assembleiaAtual.id;
      const condominioId = assembleiaAtual.id_cond; // Pega o id do condomínio correto
      const response = await fetch(
        `${API_URL}/assembleia/download/${assembleiaId}`,
      );
      if (!response.ok) {
        throw new Error("Falha ao obter a URL do anexo da assembleia.");
      }

      // GCOLLE - 24/10/24
      const getUrlDriveResponse = await fetch(
        `${API_URL}/assembleia/check-file-url/${assembleiaId}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        },
      );

      const data = await getUrlDriveResponse.json();
      console.log("URL do arquivo:", data);
      const fileUrl = data.fileUrl;
      console.log("URL do arquivo:", fileUrl);

      const blob = await response.blob();
      const reader = new FileReader();

      reader.readAsArrayBuffer(blob);
      reader.onloadend = async () => {
        try {
          const arrayBuffer = reader.result;
          const buffer = Array.from(new Uint8Array(arrayBuffer));

          const driveResponse = await fetch(
            `${API_URL}/assembleia/upload-to-drive`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ assembleiaId, buffer }),
            },
          );

          if (!driveResponse.ok) {
            throw new Error("Falha ao fazer upload para o Drive.");
          }
          const driveData = await driveResponse.json();

          const attachResponse = await fetch(
            `${API_URL}/assembleia/attach-to-event`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ assembleiaId, fileId: driveData.fileId }),
            },
          );

          if (!attachResponse.ok) {
            const attachError = await attachResponse.json();
            console.error(
              "Erro ao anexar o documento ao evento do calendário:",
              attachError,
            );
            throw new Error(
              "Falha ao anexar o documento ao evento do calendário.",
            );
          }

          const markPublishedResponse = await fetch(
            `${API_URL}/assembleia/marcar-ata-como-publicada`,
            {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ assembleiaId }),
            },
          );

          if (!markPublishedResponse.ok) {
            throw new Error("Falha ao marcar ATA como publicada.");
          }

          setIsMinutesPublished(true);
          toast.success("ATA publicada com sucesso!");
          toast.dismiss(loadingToastId); // Desativa o loading toast após sucesso

          const userResponse = await fetch(
            `${API_URL}/assembleia/usuarios/${condominioId}`,
          ); // Use o ID do condomínio aqui
          if (!userResponse.ok) {
            throw new Error("Erro ao recuperar IDs dos usuários.");
          }
          const userData = await userResponse.json();
          const userIds = userData.userIds.map(String);

          // GCOLLE - 24/10/24
          // Emitir a notificação com o link do anexo
          socket.emit("sendNotificationToCustomList", {
            userIds, // Certifique-se de que userIds esteja definido antes
            title: `Ata publicada para condomínio ${assembleiaAtual.condominio}`,
            message: "A ata foi publicada",
            type: "general",
            link_anexo: fileUrl,
          });

          socket.on("notificationResponse", (response) => {
            //console.log("Resposta do servidor:", response);
          });

          socket.on("errorNotification", (error) => {
            toast.error(`Erro ao enviar notificação: ${error}`);
          });
        } catch (error) {
          toast.error(
            `Erro durante o processo de publicação da ATA: ${error.message}`,
          );
          toast.dismiss(loadingToastId); // Remove o loading toast em caso de erro
        }
      };
    } catch (error) {
      toast.error(
        `Erro durante o processo de publicação da ATA: ${error.message}`,
      );
      toast.dismiss(loadingToastId); // Remove o loading toast em caso de erro inicial
    }
  }

  const carregarEventos = async (data) => {
    const formattedDate = dayjs(data, "DD-MM-YYYY").format("YYYY-MM-DD");
    console.log("Carregando eventos para a data formatada:", formattedDate);

    try {
      const response = await fetch(
        `${API_URL}/assembleia/eventos?data=${data}`,
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const eventos = await response.json();
      console.log("Eventos recebidos:", eventos);

      const filteredEvents = eventos.filter((evento) => {
        const isAllDayEvent = evento.start.date
          ? dayjs(evento.start.date, "YYYY-MM-DD").isSame(
              dayjs(data, "DD-MM-YYYY"),
              "day",
            )
          : false;

        const isTimedEvent = evento.start.dateTime
          ? dayjs(evento.start.dateTime).isSame(
              dayjs(data, "DD-MM-YYYY"),
              "day",
            )
          : false;

        return isAllDayEvent || isTimedEvent;
      });

      console.log("Eventos filtrados:", filteredEvents);
      setEventos(filteredEvents);
    } catch (error) {
      console.error("Erro ao buscar eventos:", error);
    }
  };

  // Efeito para carregar eventos ao mudar a data
  useEffect(() => {
    //console.log("useEffect disparado, dataSelecionada:", dataSelecionada);
    carregarEventos(dataSelecionada);
  }, [dataSelecionada]);

  // Função para manipular mudança de data
  const mudarData = (delta) => {
    const novaData = dayjs(dataSelecionada, "DD-MM-YYYY")
      .add(delta, "day")
      .format("DD-MM-YYYY");
    //console.log(`Mudando a data de: ${dataSelecionada} para: ${novaData}`);
    setDataSelecionada(novaData);
    carregarEventos(novaData);
  };

  // ------------ SSI 0051 - LCORREA - 02/09/2024 - INICIO ------------

  // Função para calcular a data de convocação
  const calcularDataConvocacao = async (novaDataAssembleia, idCond) => {
    try {
      //console.log("Iniciando cálculo da nova rota de convocação...");

      const response = await fetch(
        `${API_URL}/assembleia/calc_convocacao/${idCond}/${novaDataAssembleia.format(
          "YYYY-MM-DD",
        )}`,
      );

      if (!response.ok) {
        throw new Error(`Erro na requisição: ${response.statusText}`);
      }

      const { dt_convocacao } = await response.json();
      //console.log(`Data de convocação calculada: ${dt_convocacao}`);

      setAssembleiaAtual((prevAssembleia) => ({
        ...prevAssembleia,
        dt_convocacao: dt_convocacao,
      }));
    } catch (error) {
      console.error("Erro ao calcular a data de convocação:", error);
    }
  };
  // ------------ SSI 0051 - LCORREA - 02/09/2024 - FIM DA ALTERAÇÃO ------------

  //SSI 0093 - MATHEUS BOTELHO - 16/01/25

  function renderAvisoBadge(dtConvocacao, statusAssembleia) {
    const today = dayjs().startOf("day"); // Data atual sem horário
    const dataConvocacao = dayjs(dtConvocacao, "DD/MM/YYYY"); // Formata como data

    if (dataConvocacao.isBefore(today) && statusAssembleia < 2) {
      // Caso esteja atrasado
      return (
        <Badge
          variant="phoenix"
          bg="danger"
          className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
          style={{
            minWidth: "100px",
          }}
        >
          Atrasado
        </Badge>
      );
    } else if (dataConvocacao.isSame(today, "day") && statusAssembleia < 2) {
      // Caso seja hoje
      return (
        <Badge
          variant="phoenix"
          bg="warning"
          className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
          style={{
            minWidth: "100px",
          }}
        >
          Convocar hoje
        </Badge>
      );
    } else if (
      dataConvocacao.isSame(today.add(1, "day"), "day") &&
      statusAssembleia < 2
    ) {
      // SSI 0104 - MATHEUS BOTELHO - 27/01/25 - INICIO
      return (
        <Badge
          variant="phoenix"
          bg="success"
          className="fs-12 px-2 d-inline-flex justify-content-center align-items-center"
          style={{
            minWidth: "100px",
          }}
        >
          Convocar amanhã
        </Badge>
      );
    }
    //SSI 0104 - MATHEUS BOTELHO - 27/01/25 - FIM

    // Sem aviso
    return null;
  }

  const styles = `
      .action-buttons {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      .action-buttons .icon-button {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s;
      }
      .phoenix-table tbody tr:hover .icon-button {
        opacity: 1 !important;
        visibility: visible;
      }
    `;

  const table = useAdvanceTable({
    data: displayedAssembleias,
    columns: [
      {
        accessorKey: "id",
        header: "ID",
        cell: ({ row }) => <span>{row.original.id}</span>,
      },
      {
        accessorKey: "condominio",
        header: "Condomínio",
      },
      {
        accessorKey: "cidade",
        header: "Cidade",
      },
      //SSI 0104 - MATHEUS BOTELHO - 27/01/25 - INICIO
      {
        accessorKey: "dt_convocacao",
        header: "Convocação",
        cell: ({ row }) => formatDate(row.original.dt_convocacao), // Apenas para exibição
      },
      {
        accessorKey: "dt_assembleia",
        header: "Assembleia",
        cell: ({ row }) => formatDate(row.original.dt_assembleia), // Apenas para exibição
      },
      //SSI 0104 - MATHEUS BOTELHO - 27/01/25 - FIM
      {
        accessorKey: "tipo_assembleia",
        header: "Tipo",
      },
      {
        accessorKey: "formato_assembleia",
        header: "Formato",
      },
      {
        accessorKey: "nome_responsavel",
        header: "Secretário",
        cell: ({ row }) => row.original.nome_responsavel || "-",
      },
      {
        accessorKey: "nome_auxiliar",
        header: "Auxiliar",
        cell: ({ row }) => row.original.nome_auxiliar || "-",
      },
      {
        accessorKey: "status_assembleia",
        header: "Status",
        cell: ({ row }) => getStatusDescription(row.original.status_assembleia),
      },
      {
        id: "aviso",
        header: "Aviso",
        cell: ({ row }) =>
          renderAvisoBadge(
            row.original.dt_convocacao,
            row.original.status_assembleia,
          ),
      },
      {
        id: "acoes",
        header: "Ações",
        cell: ({ row }) => (
          <div className="action-buttons">
            <Button
              className="bn-sm text-primary p-0 m-0 icon-button"
              onClick={() => fetchAssembleiaDetalhes(row.original.id)}
              variant="link"
              title="Editar"
            >
              <Edit size={16} />
            </Button>
          </div>
        ),
      },
    ],
    pageSize: 500,
    pagination: true,
    sortable: true,
    selection: false,
    initialState: {
      sorting: [{ id: "condominio", desc: false }],
    },
  });

  //SSI 0093 - MATHEUS BOTELHO - 16/01/25 - FIM

  //SSI 0104 - MATHEUS BOTELHO - 27/01/25 - INICIO

  const handleSearchInputChange = (e) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  //SSI 0104 - MATHEUS BOTELHO - 27/01/25 - FIM

  return (
    <div>
      <style>{styles}</style>
      <PageBreadcrumb items={defaultBreadcrumbItems} />

      <h2>Painel de Assembleias</h2>
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        <button
          onClick={mesAnterior}
          title="Mês Anterior"
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="fas fa-angle-left"></span>
        </button>
        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
          {formataData(dataAtual)}
        </span>
        <button
          onClick={proximoMes}
          title="Próximo Mês"
          style={{
            background: "none",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span className="fas fa-angle-right"></span>
        </button>
      </div>

      <div>
        <div
          style={{
            width: "100%", // O contêiner ocupa toda a largura da coluna
          }}
        >
          <AssembleiaStatus mesAtual={dataAtual.month()} />
        </div>
      </div>

      <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
        <button
          onClick={handleToggleTodos}
          className="btn btn-secondary mt-4 mb-1"
          style={{ width: "180px" }}
          type="button"
        >
          {mostrarTodos ? "Meus Condomínios" : "Todos"}
        </button>
        <button
          onClick={() => setMostrarFinalizadas(!mostrarFinalizadas)}
          className="btn btn-secondary mt-4 mb-1"
          style={{ width: "180px" }}
          type="button"
        >
          {mostrarFinalizadas ? "Ocultar Finalizadas" : "Mostrar Finalizadas"}
        </button>
      </div>

      <div>
        <Row className="align-items-center mb-3">
          {/* Botão Adicionar - 10% */}
        </Row>
      </div>

      {/*SSI 0104 - MATHEUS BOTELHO - 27/01/25 - INICIO */}

      <div>
        {/* Provedor da tabela */}
        <AdvanceTableProvider {...table}>
          {/* Onde ficará nosso SearchBox e possíveis botões */}
          <div className="mb-4">
            <Row className="g-4 align-items-end">
              <Col xs={12} sm={4} md={4}>
                <Form.Group>
                  <Form.Label>Buscar Assembleias</Form.Label>
                  <SearchBox
                    placeholder="Buscar"
                    onChange={handleSearchInputChange}
                    style={{ width: "100%" }}
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-end">
                <button
                  onClick={openModal}
                  className="btn btn-primary me-1 mb-1"
                  type="button"
                >
                  + Adicionar
                </button>
              </Col>
            </Row>
          </div>

          {/* Conteúdo da tabela */}
          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <AdvanceTable
              tableProps={{
                className: "phoenix-table fs-9 table-hover",
              }}
            />
          </div>

          {/* Paginação */}
          <AdvanceTableFooter pagination />
        </AdvanceTableProvider>
      </div>

      {/*SSI 0104 - MATHEUS BOTELHO - 27/01/25 - FIM */}

      {isModalOpen && (
        <Modal
          show={isModalOpen}
          onHide={closeModal}
          backdrop="static"
          keyboard={false}
          centered
          size="lg"
        >
          <Modal.Header
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <h5 style={{ margin: 0 }}>Cadastro prévio de assembleia</h5>
            <Button
              variant="light"
              onClick={closeModal}
              style={{
                border: "none",
                background: "transparent",
                padding: "0",
              }}
            >
              <CloseIcon />
            </Button>
          </Modal.Header>
          <Modal.Body style={{ padding: "2rem" }}>
            <form onSubmit={handleSubmit}>
              <div style={{ display: "flex", gap: "20px" }}>
                {/* Coluna Esquerda */}
                <div style={{ flex: 1 }}>
                  {/* Condomínio */}
                  <div style={{ marginBottom: "20px" }}>
                    <label
                      htmlFor="condominioSelect"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "5px",
                        display: "block",
                      }}
                    >
                      Condomínio
                    </label>
                    <select
                      id="condominioSelect"
                      className="form-select" // Classe Bootstrap para estilizar
                      value={condominio?.chave || ""} // Define o valor selecionado
                      onChange={(event) => {
                        const selectedKey = Number(event.target.value);
                        const selectedCondominio = condominios.find(
                          (condominio) => condominio.chave === selectedKey,
                        );
                        setCondominio(selectedCondominio);
                        if (selectedCondominio) {
                          fetchDiasConvocacao(selectedCondominio.chave);
                        } else {
                          setDiasConvocacao(""); // Reseta caso nenhum condomínio seja selecionado
                        }
                      }}
                    >
                      <option value="" disabled>
                        Selecione um condomínio...
                      </option>
                      {condominiosFiltrados.map((condominio) => (
                        <option key={condominio.chave} value={condominio.chave}>
                          {condominio.fantasia}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "20px",
                      marginBottom: "15px",
                    }}
                  >
                    <Form.Group controlId="convocacao" className="me-3">
                      <Form.Label>Convocação</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Convocação"
                        disabled={!condominio || !editavel}
                        style={{
                          width: "150px",
                          textAlign: "center",
                        }}
                        value={diasConvocacao}
                        onChange={(e) => setDiasConvocacao(e.target.value)}
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      style={{
                        minWidth: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        color: "white",
                        padding: "0px",
                        backgroundColor: editavel ? "red" : "green",
                      }}
                      onClick={() => {
                        if (editavel) {
                          salvarDiasConvocacao();
                        } else {
                          setEditavel(true);
                        }
                      }}
                    >
                      <EditIcon style={{ fontSize: "20px" }} />
                    </Button>
                    <Form.Group controlId="dataConvocacao">
                      <Form.Label>Data da Convocação</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Data da Convocação"
                        readOnly
                        className="text-center"
                        value={dataConvocacao}
                      />
                    </Form.Group>
                  </div>
                  <FormControl
                    component="fieldset"
                    className="form-section-spacing-assembleia"
                    sx={{ width: "100%" }}
                  >
                    <FormLabel
                      component="legend"
                      sx={{
                        justifyContent: "start",
                        display: "flex",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      Tipo de Dias para Convocação
                    </FormLabel>
                    <RadioGroup
                      row
                      name="tipo_dia"
                      value={tipoDia}
                      onChange={(event) => {
                        setTipoDia(event.target.value);
                        atualizarDataConvocacao(
                          event.target.value,
                          diasConvocacao,
                          selectedDate,
                        ); // Atualizar a data de convocação ao mudar o tipo de dia
                      }}
                      sx={{
                        justifyContent: "start",
                        "& .MuiFormGroup-root": {
                          justifyContent: "start",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="corridos"
                        control={<Radio size="small" />}
                        label="Dias Corridos"
                      />
                      <FormControlLabel
                        value="uteis"
                        control={<Radio size="small" />}
                        label="Dias Úteis"
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControl
                    component="fieldset"
                    className="form-section-spacing-assembleia"
                    sx={{ width: "100%" }}
                  >
                    <FormLabel
                      component="legend"
                      sx={{
                        justifyContent: "start",
                        display: "flex",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      Tipo
                    </FormLabel>
                    <RadioGroup
                      name="tipo"
                      value={tipoAssembleia}
                      onChange={(event) =>
                        setTipoAssembleia(event.target.value)
                      }
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        justifyContent: "start",
                        "& .MuiFormControlLabel-root": {
                          width: "33%",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="Ordinária"
                        control={<Radio size="small" />}
                        label="Ordinária"
                      />
                      <FormControlLabel
                        value="Extraordinária"
                        control={<Radio size="small" />}
                        label="Extraordinária"
                      />
                      <FormControlLabel
                        value="Instalação"
                        control={<Radio size="small" />}
                        label="Instalação"
                      />
                      <FormControlLabel
                        value="Permanente"
                        control={<Radio size="small" />}
                        label="Permanente"
                      />
                    </RadioGroup>
                  </FormControl>

                  <FormControl
                    component="fieldset"
                    className="form-section-spacing-assembleia"
                    sx={{ width: "100%", textAlign: "start" }}
                  >
                    <FormLabel
                      component="legend"
                      sx={{
                        justifyContent: "start",
                        display: "flex",
                        textAlign: "start",
                        fontWeight: "bold",
                        color: "#333",
                      }}
                    >
                      Formato
                    </FormLabel>
                    <RadioGroup
                      row
                      name="formato"
                      value={formatoAssembleia}
                      onChange={(event) =>
                        setFormatoAssembleia(event.target.value)
                      }
                      sx={{
                        justifyContent: "start",
                        "& .MuiFormGroup-root": {
                          justifyContent: "start",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="Presencial"
                        control={<Radio size="small" />}
                        label="Presencial"
                      />
                      <FormControlLabel
                        value="Remota"
                        control={<Radio size="small" />}
                        label="Remota"
                      />
                      <FormControlLabel
                        value="Híbrida"
                        control={<Radio size="small" />}
                        label="Híbrida"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div>
                  {/* Data da Assembleia */}
                  <div style={{ marginBottom: "15px" }}>
                    <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
                      Data da assembleia
                    </div>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        openTo="day"
                        value={selectedDate}
                        onChange={(newValue) => {
                          setSelectedDate(newValue);
                          atualizarDataConvocacao(
                            tipoDia,
                            diasConvocacao,
                            newValue,
                          );
                        }}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
              <div style={{ minHeight: "60px", marginTop: "20px" }}>
                {showAlertPrevia && (
                  <Alert variant="warning" style={{ marginBottom: "10px" }}>
                    {alertMessagePrevia}
                  </Alert>
                )}
                <Button type="submit" variant="primary">
                  Cadastrar
                </Button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      )}

      {isEditModalOpen && (
        <Modal
          show={isEditModalOpen}
          onHide={fecharModalEdicao}
          backdrop="static"
          keyboard={false}
          centered
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "#5c6bc0", fontWeight: "bold" }}>
              Agendamento de Assembleia
            </Modal.Title>
          </Modal.Header>
          <div style={{ display: "flex", padding: "0", height: "650px" }}>
            <div style={{ width: "50%", padding: "20px" }}>
              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor: secaoExpandida === "dados" ? "#fff" : "#fff",
                }}
                onClick={() => alternarSecaoExpandida("campos")}
              >
                <h5
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <BorderColorIcon /> Dados
                </h5>
                {secaoExpandida === "campos" && (
                  <div
                    onClick={(event) => event.stopPropagation()}
                    style={{
                      padding: "10px",
                    }}
                  >
                    {/* Campo Condominio */}
                    <Form.Group controlId="condominio" className="mb-3">
                      <Form.Label>Condomínio</Form.Label>
                      <Form.Control
                        type="text"
                        value={assembleiaAtual?.condominio || ""}
                        disabled
                        style={{ textAlign: "start", fontSize: "12px" }}
                      />
                    </Form.Group>

                    {/* Data da Assembleia e Horário */}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      {/* Data da Assembleia */}
                      <Form.Group
                        controlId="dataAssembleia"
                        style={{ flex: 1 }}
                      >
                        <Form.Label>Data da Assembleia</Form.Label>
                        <Form.Control
                          type="date"
                          value={
                            assembleiaAtual?.dt_assembleia
                              ? new Date(assembleiaAtual.dt_assembleia)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          onChange={(e) => {
                            const novaDataAssembleia = e.target.value;
                            setAssembleiaAtual((prev) => ({
                              ...prev,
                              dt_assembleia: novaDataAssembleia,
                            }));
                            calcularDataConvocacao(
                              novaDataAssembleia,
                              assembleiaAtual.id_cond,
                            );
                          }}
                          style={{ fontSize: "12px", textAlign: "start" }}
                        />
                      </Form.Group>

                      {/* Horário */}
                      <Form.Group controlId="horario" style={{ flex: 1 }}>
                        <Form.Label>Horário</Form.Label>
                        <Form.Control
                          type="time"
                          value={
                            assembleiaAtual?.hr_assembleia
                              ? assembleiaAtual.hr_assembleia.substring(0, 5)
                              : ""
                          }
                          onChange={(e) => {
                            const time = e.target.value;
                            const minutes = parseInt(time.split(":")[1], 10);
                            const adjustedMinutes = Math.round(minutes / 5) * 5;
                            const adjustedTime =
                              time.split(":")[0] +
                              ":" +
                              (adjustedMinutes < 10 ? "0" : "") +
                              adjustedMinutes;
                            setAssembleiaAtual((prev) => ({
                              ...prev,
                              hr_assembleia: adjustedTime,
                            }));
                          }}
                          style={{ fontSize: "12px", textAlign: "start" }}
                        />
                      </Form.Group>
                    </div>

                    {/* Data da Convocação */}
                    <Form.Group controlId="dtConvocacao" className="mb-3">
                      <Form.Label>Data da Convocação</Form.Label>
                      <Form.Control
                        type="date"
                        value={
                          assembleiaAtual?.dt_convocacao
                            ? new Date(assembleiaAtual.dt_convocacao)
                                .toISOString()
                                .split("T")[0]
                            : ""
                        }
                        disabled
                        style={{ fontSize: "12px", textAlign: "start" }}
                      />
                    </Form.Group>

                    {/* Tipo de Assembleia */}
                    <div style={{ marginBottom: "20px" }}>
                      <Form.Label>Tipo de Assembleia</Form.Label>
                      <div>
                        {[
                          "Ordinária",
                          "Extraordinária",
                          "Instalação",
                          "Permanente",
                        ].map((tipo) => (
                          <Form.Check
                            inline
                            key={tipo}
                            type="radio"
                            label={tipo}
                            name="tipoAssembleia"
                            value={tipo}
                            checked={assembleiaAtual?.tipo_assembleia === tipo}
                            onChange={(e) =>
                              setAssembleiaAtual((prev) => ({
                                ...prev,
                                tipo_assembleia: e.target.value,
                              }))
                            }
                          />
                        ))}
                      </div>
                    </div>

                    {/* Formato */}
                    <div>
                      <Form.Label>Formato</Form.Label>
                      <div>
                        {["Presencial", "Remota", "Híbrida"].map((formato) => (
                          <Form.Check
                            inline
                            key={formato}
                            type="radio"
                            label={formato}
                            name="formato"
                            value={formato}
                            checked={
                              assembleiaAtual?.formato_assembleia === formato
                            }
                            onChange={(e) =>
                              setAssembleiaAtual((prev) => ({
                                ...prev,
                                formato_assembleia: e.target.value,
                              }))
                            }
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                onClick={() => alternarSecaoExpandida("pauta")}
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor: secaoExpandida === "pauta" ? "#fff" : "#fff",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h5
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <LibraryBooksIcon />
                    Pauta
                  </h5>
                </div>
                {secaoExpandida === "pauta" && (
                  <div
                    style={{ marginTop: "10px" }}
                    onClick={(event) => event.stopPropagation()} // Adicionado para impedir a propagação do evento
                  >
                    <Form.Group controlId="assuntosPauta" className="mb-3">
                      <Form.Label>Assuntos da Pauta</Form.Label>

                      <Form.Control
                        as="textarea"
                        rows={4}
                        value={
                          assembleiaAtual
                            ? assembleiaAtual.assuntos_assembleia || ""
                            : ""
                        }
                        onChange={(e) =>
                          setAssembleiaAtual({
                            ...assembleiaAtual,
                            assuntos_assembleia: e.target.value,
                          })
                        }
                        style={{ fontSize: "12px" }}
                      />
                    </Form.Group>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "end",
                          marginBottom: "20px",
                          gap: "10px", // Espaçamento entre os elementos
                        }}
                      >
                        <Form.Group controlId="secretario" style={{ flex: 1 }}>
                          <Form.Label>Secretário</Form.Label>
                          <Form.Select
                            value={assembleiaAtual.id_func_responsavel || ""}
                            onChange={(e) => {
                              const selectedSecretario = secretarios.find(
                                (secretario) =>
                                  secretario.id_func === Number(e.target.value), // Converta para número
                              );
                              setAssembleiaAtual({
                                ...assembleiaAtual,
                                id_func_responsavel: e.target.value
                                  ? Number(e.target.value)
                                  : null, // Converta aqui também
                                nome_responsavel: selectedSecretario
                                  ? selectedSecretario.nome
                                  : "",
                              });
                            }}
                            label="Secretário"
                          >
                            <option value="">Nenhum</option>
                            {secretarios.map((secretario) => (
                              <option
                                key={secretario.id_func}
                                value={secretario.id_func}
                              >
                                {secretario.nome}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>

                        <button
                          className="btn btn-secondary me-1 mb-1"
                          onClick={handleAddSecretary}
                        >
                          <AddIcon />
                        </button>
                        <button
                          className="btn btn-secondary me-1 mb-1"
                          onClick={handleOpenUserModal}
                        >
                          <EditNoteIcon />
                        </button>
                      </div>

                      {/* Auxiliar */}
                      {showAuxiliar && (
                        <Form.Group controlId="auxiliar" className="mb-3">
                          <Form.Label>Auxiliar</Form.Label>
                          <Form.Select
                            value={assembleiaAtual.id_func_auxiliar || ""}
                            onChange={(e) => {
                              const selectedAuxiliar = secretarios.find(
                                (secretario) =>
                                  secretario.id_func === Number(e.target.value), // Converta para número
                              );
                              setAssembleiaAtual({
                                ...assembleiaAtual,
                                id_func_auxiliar: e.target.value
                                  ? Number(e.target.value)
                                  : null, // Converta aqui também
                                nome_auxiliar: selectedAuxiliar
                                  ? selectedAuxiliar.nome
                                  : "",
                              });
                            }}
                          >
                            <option value="">
                              <em>Nenhum</em>
                            </option>
                            {secretarios
                              .filter(
                                (secretario) =>
                                  secretario.id_func !==
                                  assembleiaAtual.id_func_responsavel,
                              )
                              .map((filteredSecretario) => (
                                <option
                                  key={filteredSecretario.id_func}
                                  value={filteredSecretario.id_func}
                                >
                                  {filteredSecretario.nome}
                                </option>
                              ))}
                          </Form.Select>
                        </Form.Group>
                      )}

                      {/* Novo campo de Observações */}
                      <Form.Group controlId="observacoes" className="mb-3">
                        <Form.Label>Observações</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={4}
                          value={
                            assembleiaAtual
                              ? assembleiaAtual.observacoes || ""
                              : ""
                          }
                          onChange={(e) =>
                            setAssembleiaAtual({
                              ...assembleiaAtual,
                              observacoes: e.target.value,
                            })
                          }
                          style={{ fontSize: "12px" }}
                        />
                      </Form.Group>

                      {/* Modal Gerenciar Usuários */}
                      <Modal
                        show={isUserModalOpen}
                        onHide={handleCloseUserModal}
                        size="lg"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>Gerenciar Usuários</Modal.Title>
                        </Modal.Header>

                        <Modal.Body
                          style={{
                            maxHeight: "400px", // Limite de altura para o conteúdo
                            overflowY: "auto", // Barra de rolagem vertical
                            padding: "20px", // Padding para espaçamento interno
                          }}
                        >
                          <Form.Group
                            controlId="adicionarUsuario"
                            className="mb-3"
                          >
                            <Form.Label>Adicionar Usuário</Form.Label>
                            <Form.Select
                              value={selectedUser}
                              onChange={handleUserSelect}
                              style={{ fontSize: "12px" }}
                            >
                              <option value="">
                                <em>Nenhum</em>
                              </option>
                              {Object.keys(groupedUserOptions)
                                .sort((a, b) => {
                                  if (a === "Maringá") return -1;
                                  if (b === "Maringá") return 1;
                                  if (a === "Sem cidade sede") return 1;
                                  if (b === "Sem cidade sede") return -1;
                                  return a.localeCompare(b);
                                })
                                .map((city) => [
                                  <ListSubheader key={city}>
                                    {city}
                                  </ListSubheader>,
                                  ...groupedUserOptions[city].map((user) => (
                                    <option
                                      key={user.id_func}
                                      value={user.id_func}
                                    >
                                      {user.nome}
                                    </option>
                                  )),
                                ])}
                            </Form.Select>
                          </Form.Group>

                          <List>
                            {activeUsers.map((user) => (
                              <ListItem
                                key={user.id_func}
                                secondaryAction={
                                  <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() =>
                                      removeUserFromAssembly(user.id_func)
                                    }
                                    sx={{ color: "#ef5350" }}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                }
                                style={{
                                  backgroundColor: "#f9f9f9",
                                  marginBottom: "2px",
                                }}
                              >
                                <ListItemText primary={user.nome} />
                              </ListItem>
                            ))}
                          </List>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                )}
              </div>

              <div
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "10px",
                  marginBottom: "20px",
                  padding: "10px",
                  cursor: "pointer",
                  backgroundColor:
                    secaoExpandida === "checklist" ? "#fff" : "#fff",
                }}
                onClick={() => alternarSecaoExpandida("checklist")}
              >
                <h5
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <ChecklistIcon /> Checklist
                </h5>
                {secaoExpandida === "checklist" && (
                  <div
                    onClick={(event) => event.stopPropagation()}
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <h6 style={{ marginBottom: "15px", fontWeight: "bold" }}>
                        Pré-Assembleia
                      </h6>
                      {checklistItems.slice(0, 7).map((item, index) => (
                        <Form.Check
                          key={index}
                          style={{ marginBottom: "10px" }}
                        >
                          <Form.Check.Input
                            type="checkbox"
                            checked={checklistStatus[index]}
                            onChange={() => handleChecklistChange(index)}
                            disabled={
                              !(
                                checklistStatus[index] ||
                                index === checklistStatus.lastIndexOf(true) + 1
                              )
                            }
                          />
                          <Form.Check.Label>{`${index + 1}. ${item}`}</Form.Check.Label>
                        </Form.Check>
                      ))}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "10px",
                          marginTop: "20px",
                          alignItems: "center", // Adiciona alinhamento vertical ao centro para os ícones
                        }}
                      >
                        {assembleiaAtual.anexo_assembleia ? (
                          <>
                            <OverlayTrigger
                              overlay={<Tooltip>Baixar Anexo Atual</Tooltip>}
                            >
                              <a
                                href={`${API_URL}/assembleia/download/${assembleiaAtual.id}`}
                                download={
                                  assembleiaAtual.nome_arquivo ||
                                  "Download_Assembleia.pdf"
                                }
                                style={{
                                  textDecoration: "none",
                                  display: "flex",
                                  alignItems: "center",
                                  color: "#3874ff",
                                }}
                              >
                                <CloudDownloadIcon />
                              </a>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={<Tooltip>Remover Anexo</Tooltip>}
                            >
                              <button
                                onClick={removerAnexo}
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  color: "#ed2000",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                              overlay={<Tooltip>Publicar Ata</Tooltip>}
                            >
                              <button
                                onClick={() => publicarAta()}
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: 0,
                                  color: "#25b003",
                                  cursor: "pointer",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <PublishIcon />
                              </button>
                            </OverlayTrigger>
                          </>
                        ) : (
                          <Form.Control
                            type="file"
                            onChange={(e) => {
                              if (e.target.files[0]) {
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.onload = async (event) => {
                                  await atualizarAnexo(
                                    event.target.result,
                                    file.name,
                                  );
                                };
                                reader.readAsDataURL(file);
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <h6 style={{ marginBottom: "15px", fontWeight: "bold" }}>
                        Pós-Assembleia
                      </h6>
                      {checklistItems.slice(7, 16).map((item, index) => {
                        let realIndex = index + 7;
                        return (
                          <Form.Check
                            key={realIndex}
                            style={{ marginBottom: "10px" }}
                          >
                            <Form.Check.Input
                              type="checkbox"
                              checked={checklistStatus[realIndex]}
                              onChange={() => handleChecklistChange(realIndex)}
                              disabled={
                                !isAssemblyPublished ||
                                !(
                                  checklistStatus[realIndex] ||
                                  realIndex ===
                                    checklistStatus.lastIndexOf(true) + 1
                                )
                              }
                            />
                            <Form.Check.Label>{`${realIndex + 1}. ${item}`}</Form.Check.Label>
                          </Form.Check>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Conteúdo da direita (50%) */}
            <div
              style={{
                width: "50%",
                borderLeft: "1px solid #ccc",
                padding: "20px",
                overflowY: "auto",
              }}
            >
              {/* Agenda */}
              <div style={{ marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <h5 style={{ margin: 0 }}>Agenda</h5>
                  <div>
                    <span
                      style={{
                        marginRight: "10px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => mudarData(-1)}
                      aria-label="Previous Day"
                    >
                      &lt;
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                      {dataSelecionada}, {nomeDoDia}
                    </span>
                    <span
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={() => mudarData(1)}
                      aria-label="Next Day"
                    >
                      &gt;
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    height: "300px",
                    overflowY: "auto",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  {eventos.map((evento) => (
                    <div
                      key={evento.id}
                      style={{
                        marginBottom: "10px",
                        borderBottom: "1px solid #ddd",
                        paddingBottom: "5px",
                      }}
                    >
                      {/* SSI 0093 - MATHEUS BOTELHO - INICIO */}
                      <h6 style={{ marginBottom: "5px" }}>{evento.summary}</h6>
                      <p style={{ margin: 0 }}>
                        {evento.start.dateTime
                          ? `${evento.start.dateTime.substring(11, 16)}`
                          : "Todo o dia"}{" "}
                        -{" "}
                        {evento.end.dateTime
                          ? `${evento.end.dateTime.substring(11, 16)}`
                          : ""}
                      </p>
                      {/* SSI 0093 - MATHEUS BOTELHO - FIM */}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                {/* Botões de Ação */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center", // Alinha os botões no centro horizontalmente
                    gap: "10px",
                    marginBottom: "20px",
                  }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={handleSave}
                    style={{
                      padding: "10px 20px",
                    }}
                  >
                    Salvar Dados
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={handleDelete}
                    style={{
                      padding: "10px 20px",
                    }}
                  >
                    Excluir
                  </button>
                  {!isAssemblyPublished && (
                    <button
                      className="btn btn-success"
                      onClick={handlePublish}
                      style={{
                        padding: "10px 20px",
                      }}
                    >
                      Publicar
                    </button>
                  )}
                </div>

                {/* Indicadores de Status */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start", // Alinha os textos no início da coluna
                    gap: "10px",
                  }}
                >
                  {/* Status - Pronto para publicar */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: isDataSaved ? "green" : "red",
                        border: isDataSaved ? "none" : "2px solid red",
                      }}
                    >
                      {!isDataSaved && (
                        <i
                          className="bi bi-x-circle"
                          style={{
                            fontSize: "15px",
                            color: "red",
                          }}
                        ></i>
                      )}
                    </div>
                    <span>Pronto para publicar</span>
                  </div>

                  {/* Status - Assembleia publicada */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: isAssemblyPublished ? "green" : "red",
                        border: isAssemblyPublished ? "none" : "2px solid red",
                      }}
                    >
                      {!isAssemblyPublished && (
                        <i
                          className="bi bi-x-circle"
                          style={{
                            fontSize: "15px",
                            color: "red",
                          }}
                        ></i>
                      )}
                    </div>
                    <span>Assembleia publicada</span>
                  </div>

                  {/* Status - Ata Publicada */}
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: isMinutesPublished ? "green" : "red",
                        border: isMinutesPublished ? "none" : "2px solid red",
                      }}
                    >
                      {!isMinutesPublished && (
                        <i
                          className="bi bi-x-circle"
                          style={{
                            fontSize: "15px",
                            color: "red",
                          }}
                        ></i>
                      )}
                    </div>
                    <span>Ata Publicada</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <span>
                      Alterado por{" "}
                      <strong>{assembleiaAtual.nome_alteracao}</strong> em{" "}
                      <strong>
                        {assembleiaAtual.data_alteracao
                          ? dayjs(assembleiaAtual.data_alteracao).format(
                              "DD/MM/YYYY HH:mm",
                            )
                          : ""}
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Assembleia;
